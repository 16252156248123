.Administer_Personnel_li_width {
    float: left;
    width: 33.33%;
}
.Administer_Personnel_perent_li_width {
    width: calc(100% - 350px);
}
.invalid_check_box {
    position: absolute;
    left: 0;
    top: -27px;
    font-size: 12px;
}
.model_num_align {
    text-align: right;
    padding-right: 45px;
    font-size: 13px;
}
.model_clear_filter {
 
    text-decoration: underline;
    margin-top: 7px;
    display: inline-block;
    font-size: 12px;
    color: black;
}
.filter_icon {
    font-size: 23px;
    cursor: pointer;
    position: relative;
    top: 4px;
}
.filter_icon.active {
    color: #0e38e4;
}
.ant_body_padding .ant-modal-body {
    padding-top: 0px;
}
.height_26 {
    height: 26px;
}
.filter_label {
    color: black;
    font-size: 12px;
    font-weight: 100;
}
.model_clear_filter:hover {
    color: rgb(36, 35, 35);
}
.space_filter .col-md-6 {
    margin-bottom: 10px;
}
.Assign_template_treatment_table .rt-tbody  {
    height: calc(100vh - 460px);
}
.Assign_template_treatment_table .rt-thead .rt-tr:nth-child(1) .rt-th:nth-child(1) {
    visibility: hidden;
}

.Assign_template_treatment_table_fcell .rt-tbody  {
    height: calc(100vh - 285px);
}
