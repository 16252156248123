.scanned_search {
    margin-top: 3px;
}
.scanned_search > input{
    display: block;
    width: 300px;
    height: 32px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
@media screen and (max-width:1180px) {
    .responsive_input_bars_position_two {
        
    }
    .responsive_input_bars_position_one {
        
    }
    .responsive_input_bars_position_one .w_200p {
        width: 180px !important;
    }
    .responsive_input_bars_position_two input {
        width: 180px ;
    }
}
@media (min-width: 1181px) and (max-width:1300px) {
    .responsive_input_bars_position_two {
        
    }
    .responsive_input_bars_position_one {
        
    }
    .responsive_input_bars_position_one .w_200p {
        width: 250px !important;
    }
    .responsive_input_bars_position_two input {
        width: 250px ;
    }
}
.fr{
    float: right !important;
}