.custom-pagination .pagination-bottom {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 3px;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);
    border-top: 2px solid rgba(0,0,0,0.1);
    box-sizing: border-box;
    border: 1px solid #e8e8e8;
    margin-top: -1px;
}
.custom-pagination .-button{
    opacity: .5;
cursor: default;
appearance: none;
display: block;
width: 100%;
height: 100%;
border: 0;
border-radius: 3px;
padding: 6px;
font-size: 1em;
color: rgba(0,0,0,0.6);
background: rgba(0,0,0,0.1);
transition: all .1s ease;
outline-width: 0;
box-sizing: border-box;
touch-action: manipulation;
text-align: center;
}
.custom-pagination  .active :hover{
    color: white;
    background-color: #9e9e9e;
    cursor: pointer;
}
.custom-pagination .nav{
    padding: 0px !important;
    cursor: pointer;
}
.custom-pagination .-pageJump input {
    width: 70px;
    text-align: center;
    border: 1px solid rgba(0,0,0,0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline-width: 0;
    display: inline-block;
    margin: 3px 10px;
    white-space: nowrap;
}
.custom-pagination .-pageJump .-pageSizeOptions{
    margin: 3px 10px;
    float: right;
}
.custom-pagination .-pageJump select {
    border: 1px solid rgba(0,0,0,0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline-width: 0;
}