.btn-group-duplicate {
    /* box-shadow: 0 5px 10px #ddd; */
    height: fit-content;
}

.duplicate-name-main{
	width:  auto
}

.duplicate-name{
	width: 13%;
	float: left !important;
}

.duplicate-name-input{
	width: 87%;
	float: left;
	padding-left: 6px !important;
}

.duplicate-speciality-family-main{
	width:  auto;
	display: flex;
	padding-top:10px;
}

.duplicate-speciality-family{
	width: 13%;
	float: left !important;
	color: black;
}

.duplicate-speciality-family-dropdown{
	width: 67%;
	float: left

}
.CodeHolder {
	width: 20%;
	float: left;
	border: 1px solid #ccc;
	text-align: center;
	justify-content: center;
	display: flex;
	line-height: 2.6;
	height: 42px;
	overflow: hidden;
 }

.btn-group-duplicate .active-btton {
	color: #fff;
    background: #3740f2;
    position: relative;
}

.display-flex{
	display: flex;
}

.custom-modal-content {
	display: flex;
	flex-direction: column;
	line-height: 2;
  }

  .group {
	margin-bottom: 10px; /* Adjust the space between groups */
  }
  
  .spacer {
	margin-bottom: 20px; /* Adjust the space between groups */
  }

  .custom-duplicate-select{
	width: 200px;
  }

.validation_text {
	right: 0px;
  top: 3px;
  font-size: 12px;
  color: #d07492;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  line-height: 2;

}

.line-after {
	position: relative;
	overflow: hidden;
	display: block;
  
	span {
	  vertical-align: baseline;
	  display: block;
	  position: relative;
  
	  &:after {
		content: '';
		position: absolute;
		width: 100%;
		top: 50%;
		margin-left: 10px;
		border-top: 1px solid #ccc;
	  }
	}
  }
  .duplicate-name-main .name_field .anticon-info-circle {
	position: absolute;
	right: 10px;
	top: 8px;
	cursor: pointer;
	font-size: 16px;
  }