@import "../node_modules/@syncfusion/ej2-react-diagrams/styles/material.css";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";

body {
  margin: 0;
  padding: 0;
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-size: 12px;
}
label {
  font-weight: 100;
}
::-webkit-scrollbar {
  display: block !important;
  width: 5px !important; 
  height: 5px !important; 
}

::-webkit-scrollbar {
width: 5px;
height: 5px !important; 
}

*:not( .ant-table-body, .HP_Section_scroll, .diagram_holder, .virtualScroll-H)::-webkit-scrollbar { 
  display: none; 
}

::-webkit-scrollbar-thumb {
background: #6e6e6e;
border-radius: 20px;
}
.loding_spinner_screen {
  width: 25%;
  height: 50%;
  position: absolute;
  top:25%;
  right: 25%;
  left: 37%;
  bottom: 25%;
  background-color: black;
  opacity: 0.6;
  display: flex;
}

.loading_spinner_screen_image_loader {
  display: flex;
  position: absolute;
}

.custom_loader.loading_spinner_screen_image_loader {
  position: relative;
  margin-top: 75px;
}

.custom_loader img {
  width: 65%;
}

.image_loader {
  left: 50%;
  top: 50%;
}

.loding_spinner_screen_revert_maping {
  width: 25%;
    height: 50%;
    position: absolute;
    top: 15%;
    right: 25%;
    left: 25%;
    bottom: 25%;
    background-color: black;
    opacity: 0.6;
    display: -ms-flexbox;
    display: flex;
    z-index: 999;
}
.primary_background_spin div {
  color: white;
}
.css-2o5izw {
  width: 100%;
}


body .Virtualized_react_table_scroll .rt-tbody {
  overflow: hidden;
}
.ant-popover-inner {
  position: relative;
}
.warning_content .row {
  border-bottom: 1px solid #dddddd;
}
.text_editor_z_index_select {
  z-index: 99;
}
.react_multi_select_checkbox > div > button {
    width: 100%;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 32px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    padding: 5px 15px !important;
    box-shadow: none !important;
    color: inherit !important;
}
.react_multi_select_checkbox > div > div:nth-of-type(1) {
   width: 100% !important;
   left: 0;
   right: 0;
   box-shadow: 0px 0px 4px 0px #dddddd;
   /* padding: 5px 0px; */
   /* margin-top: 3px !important; */
}
.react_multi_select_checkbox > div:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(2) > div {
  padding: 0px 6px !important;
}
.react_multi_select_checkbox > div:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(2) {
  display: flex;
  align-items: center;
}

.multi-select-checkbox .css-g1d714-ValueContainer, .group input:focus ~ label, input:valid ~ label{
  font-size: 14px;color: hsl(0, 0%, 20%) !important;
}
.multi-select-checkbox .css-4ljt47-MenuList .css-9gakcf-option  label{
  color: white !important;
}
.drop_max_height_150 > div > div:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(2) > div:nth-of-type(1)  {
  max-height: 150px;
  overflow: auto;
}
.drop_max_height_180 > div > div:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(2) {
  max-height: 180px;
  overflow: auto;
}
.z_index_100 {
  z-index: 100;
  position: relative;
}
.z_index_99 {
  z-index: 99;
  position: relative;
}
.z_index_9x4 {
  z-index: 9999 !important;
}
.clinical_pathway_container .ant-btn {
  font-size: 12px !important
}
.clinical_pathway_container .form-control {
  font-size: 12px !important
}
.clinical_pathway_container .ant-select-selection__placeholder {
  font-size: 12px !important;
}
.clinical_pathway_container .edit_button_clp {
  font-size: 12px;
}

.clinical_pathway_container .ant-collapse {
  font-size: 12px;
}
.clinical_pathway_container .ant-table {
  font-size: 12px;
}
/* .additionalInfo_wraper .rdw-dropdown-wrapper{
  z-index: 5 !important;
} */

.clp_table .rt-tbody{
  height: calc(100vh - 440px) !important;
}
.clp_table_cover .rt-tbody{
  height: calc(100vh - 324px) !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{ height: 36px;}

.row-dragging {
  z-index: 10000 !important;
}
.ant-message-error .anticon-close-circle{
	display: none;
}