.fullview_modal{
    width: calc(100vw - 20px ) !important;
    top: 10px;
}
.fullview_modal .ant-modal-content{ height: calc(100vh - 20px) !important;}
.fullview_modal .steps_label_special{font-size: 13px !important;}
.fullview_modal .with_border {padding: 10px 13px !important}
.fullview_modal .ant-modal-body {
    height: calc(100vh - 75px) !important;
    max-height: none;
    padding-top: 10px;
}
.fullbody_table {
/* max-height: calc(100vh - 275px); */
height: calc(100vh - 275px);
overflow-y: auto;
width: 100%;
border-top: 1px solid #ddd;
}
.fullbody_table .ant-modal-body{
    padding-top: 10px;
    position: relative;
}
.fullbody_table table{
    width: 100%;
    border: 1px solid #ddd;
    border-collapse: collapse;   
    color: black;
    margin-top: -1px;
}
.fullbody_table th{
    /* border-bottom: 3px solid #3740f2; */
    background-color: #f3f4f8 !important;
    position: sticky;
    top: 0;
    z-index: 6 !important;
}
.fullbody_table td {
border: 1px solid #ddd;
padding: 9px;
border-collapse: collapse;
}

.fullbody_table .subHead{
    background-color: #ddd;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    position: sticky;
    top: 38px;
    z-index: 9 !important;
}
.fullbody_table .css-1jllj6i-control{display: none !important;}
.fullbody_table .react_multi_select_checkbox{ min-width: 350px !important;}
.code_details{
    display: block;
    float: left;
}
.code_details div{
    width: auto;
float: left;
margin-left: 10px;
margin-top: 3px;
}
.code_details div span{
padding: 0px 3px;
border: 1px solid #ccc;
font-weight: bold;
margin-right: 10px;
}
.type_block{
    width:500px;
    float: left;
}
.clp_block{
    width: calc(100% - 500px);
    float: left;
}
.empty_data{
height: calc(100vh - 280px) !important;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
color: #ccc; 
border: 1px solid #ccc;
}
.empty_data svg {
    width: 60px;
    height: 60px;
}
.display-grid{display: grid;}

.columnResizer {
    background-color: #ccc;
    width: 1px;
    min-width: 1px;
    max-width: 1px;
    padding: 0 0 0 0 !important;
    border: none;
}
.pop_button_wrapper{
    margin: 10px 0px;
    position: absolute;
    width: 97%;
    bottom: 1px;
    right: 23px;
}
.z-index-99{
    z-index: 99 !important;
}
/* .fullbody_table .react_multi_select_checkbox >div:nth-of-type(1) > div:nth-of-type(1){
    z-index: 97 ;
} */
.css-1nmdiq5-menu {
    z-index: 92 !important;
}