.slide-card {
    /* background-color: rgb(225, 227, 238); */
}
  
.slide-card > div {

float: left;
margin: 5px;

}
.slide-card > div button {
width: 25px;
width: 63px;
}
.ant-carousel .slick-dots li.slick-active button {
    background: #151414;
  }
.ant-carousel .slick-dots li button {
background: #282a44;

}
.ant-carousel .slick-dots-bottom {
bottom: -3px;
}
.ant-slider .ant-slider-handle {
  border-color: #3740f2;
  background-color: #3740f2;
}
.ant-slider .ant-slider-step {
  background: #7c6969;

}
.ant-slider-dot-active { border-color: #3740f2; }
.ant-slider-dot { background-color: #3740f2;border: 2px solid #3740f2;}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #2a2a8a;
}

.externalink-diagram {
  width: 296px;
}
.mom-date-picker {
  float: left;
  position: absolute;
  z-index: 999;
}