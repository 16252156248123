.sideBar-wrapper{
    height: calc(100% - 119.8px);
    width: 350px;
    background: #fff;
    position: absolute;
    right: 0px;
    padding: 7px;
    border: 1px solid #ccc;
    padding-top: 107.8px;
    -webkit-box-shadow: -10px -6px 10px -10px rgba(96,102,143,1);
    -moz-box-shadow: -10px -6px 10px -10px rgba(96,102,143,1);
    box-shadow: -10px -6px 10px -10px rgba(96,102,143,1);
    transition: all 0.3s ease;
}
.sideBar-wrapper h4 { font-size: 15px; color: black; text-align: center;}

.sideBar-body{
    background: #fff;
    width: 100%;
    /* height: 100%; */
    height: calc(100vh - 255px);
    margin-top: 7px;
    /* border: 1px solid #ccc; */
    float: left;
    padding: 0px;
    overflow-y: scroll;
    padding-top: 0px;
    padding-bottom: 45px;
}
.sideBar-tab {
    height: 18px;
    margin-bottom: 0px;
    width: 100%;
    float: left;
    margin-top: 0px;
}
.sideBar-tab li {
    float: left;
    width: auto;
    /* padding: 8px 15px; */
    font-size: 14px;
    cursor: pointer;
    height: auto;
    text-align: center;
    border-bottom: 3px solid #fff;
    margin: 0px;
    margin-right: 25px;
    font-size: 14px;
}
.sideBar-tab li[data-active="true"] {
    color: #3740f2;
    border-color:#3740f2;
}
.diagram-data-header{
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 9;
    padding-top: 7px;
}
.edit-task-input-field::placeholder {
    color: rgb(92, 91, 91);
    margin-left: 5px;
    font-size: 13px;
 }

.popup_modal_content .select-link input {
    border: transparent !important;
    background-color: transparent !important;
}
.react_multi_select_checkbox  label {width: auto;}
.react_multi_select_checkbox input{
    height: auto !important;
    border: transparent !important;
    background-color: transparent !important;
}
.steps_substeps_create_icon {
    color: #3740f2;
}
.steps_substeps_create_icon svg {
    height: 20px;
    width: 22px;
    margin-left: 2px;
}
.create-sub-step-modal {
    height: 60px;
}
.create-sub-step-modal .title{
    margin-left: 13px;
}

.diagram-checkBox-list .multi-select-checkbox input { float: left; margin-right: 5px;}
.diagram-checkBox-list .multi-select-checkbox label { width: 239px !important;}

.step-name {
    font-weight:bold;
    font-size: medium;
}

/* .e-diagram-endpoint-handle {
    fill: rgb(126, 190, 219);
    stroke: #24039e;
    stroke-width: 3px;
   } */
  #diagram_SelectorElement  path {
    fill: rgb(11, 12, 11);
    stroke: #06050a;
    stroke-width: 1px;
   }

.problem_type_drop_down {
    width: 100%;
    border-radius: 2px;
}