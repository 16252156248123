/* contents */

.mom_benchmark_text {
    font-size: 19px;
    font-weight: 300;
}

.mr444 {
    margin-right: 3%;
}

.mr333 {
    margin-right: 3%;
}

.color_box {
    height: 300px;
    border-radius: 6px;
    cursor: pointer;
    width: 22.75%;
    float: left;
}
.color_box_open {
    width: 25.75% !important;
    margin-right: 0 !important;
}

.color_box_open_right {
    width: 22.75% !important;
    margin-right: 0 !important;
}

.box-items .box_item_icon {
    width: 50px;
}
.box-items .box_item_name {
    font-size: 20px;
}

.main_container_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    text-align: center;
}
.main_container_footer span {
    font-size: 10px; 
    margin-top: 7px;
    display: inline-block;
}
.height_66calc {
    height: calc(100% - 66px);
    overflow-y: auto;
}

.main-container-contents {
    height: calc(100% - 50px);
}

.small_section {
    width: 90px;
    display: flex;
    align-items: center;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.small_section img {
    width: 35px;
}

.large_section {
    max-height: 300px;
    overflow: scroll;
    min-height: 300px;
}
.color_bg_large {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.first_a {
    width: 100%;
    display: inline-block;
    padding: 10px 15px;
    text-decoration: none;
}

.side_bar_section .inner_a .first_a {
    padding:10px 20px !important;
}
.side_bar_section .inner_a .first_a:hover{
    padding:10px 20px!important;
}

.inner_a .second_a {
    width: 100%;
    display: inline-block;
    padding: 10px 15px 10px 36px;
    text-decoration: none;
}
.large_section .inner_a .second_a {
    color:#5c5c5c;
}
@media only screen and (min-width:768px) and (max-width:991px) {
    .mom_benchmark_text {
        margin-top: 35px;
    }
    .bottom_section {
        padding:0px 50px;
    }
}

@media only screen and (max-width:991px) {
    .mom_benchmark_text {
        margin-top: 35px;
    }
    
    .navbar-brand .logo-nav {
        position: relative;
        width: auto;
        height: 44px;
        cursor: pointer;
        margin-top: 6px;
    }
    
    .color_box {
        height: 300px;
        border-radius: 6px;
        cursor: pointer;
        width: 100%;
        float: left;
        margin-bottom: 20px;
    }
    .mr444 {
        margin-right: 0% !important;
    }
    .mr333 {
        margin-right: 0% !important;
    }
    .main-container-contents {
        height: auto !important;
    }
    .main_container_footer {
        position: relative;
    }
    .navbar {
        position: sticky;
        top: 0;
        z-index: 999;
    }
    .color_box_open_right {
        width: 100% !important;
        margin-right: 0 !important;
    }
    .mr10p {
        margin-right: 0%;
    }
    .color_box_open {
        width: 100% !important;
    }
}

@media only screen and (max-width:767px) {
    .main_container_footer {
        position: relative;
    }
    .bottom_section {
        padding:0px 20px;
    }
}
.font-size-14{
    font-size: 14px !important;
}
.dash_card .first_a {
    text-align: left;
}