.create_category_personal_width_100 {
    width: 100% !important;
}
.common_label {
    font-size: 12px;
    margin-bottom: 8px;
}
.min_width_12_px {
    min-width: 12px;
    display: inline-block;
    vertical-align: top;
}
.invalid_label {
    color: rgb(243, 83, 83);
}