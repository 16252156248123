.loader_wrap{
    height: 100%;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader_container {
    display: grid;
}
.margin_auto {
    margin: auto;
    text-align: center;
}
.h_100 {
    height: 100%;
}
.w_200 {
    width: 200px;
}
.margin_auto .log { width: 250px;}
.margin_auto .loader { width: 150px;}