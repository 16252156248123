.rank_div {
    margin-left: auto !important;
    width: 100%;
    text-align: center;
    display: inline-flex;
}
.rank_div a {
    width: 20px;
    height: 20px;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
}
.subsidy_bold {
    font-weight: bold;
    font-family: 'Proxima Nova Bold';
}

.rank_div a:hover svg {
    background-color: transparent;
}
.rank_value {
    width: calc(100% - 40px);
    padding: 0px 10px;
}
.exit_element_table_active {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 8px;
}
.color_black {
    color: #000000;;
}
.d-flex {
    display: flex;
} 
.align-items-end {
    align-items: flex-end;
} 
.justify-content-between {
    justify-content: space-between;
}
.align-items-center {
    align-items: center;
}
.flex-wrap {
    flex-wrap: wrap;
}
.justify-content-end {
    justify-content: flex-end;
}
.invalid_color {
    color:#d07492 !important;
}