.color_black {
    color: black !important;
}

/* disable checkbox */

.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #cacaca !important;
    border-color: #b9b7b7 !important;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
    border-color:#fff !important;
}

.toggleClassSelectedStandard{
    color: white !important;
    background-color: #105cf2 !important;
    border-radius: 4px 0px 0px 4px !important;
    min-height: 27px;
   
}

.toggleClassSelectedReal{
    color: white !important;
    background-color: #105cf2 !important;
    border-radius: 0px 4px 4px 0px !important;
    min-height: 27px;
}

.btn-group-toggle {
    display: inline-flex !important;
    float: left;
    box-shadow: 0 5px 10px #ddd; 
}

.invalid {
    position: absolute;
    right: 15px;
    top: 3px;
    font-size: 12px;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    border:none;
    box-shadow: none;
}

.position_absolute_loader {
    position: absolute;
    width: 100%;
    display: grid;
    height: 300px;
}

.edit_delete_button {
    padding:5px 15px;
    font-size: 12px;
}

.edit_color {
    cursor: pointer;
}
.check_color {
    cursor: pointer;
}
.edited_row span svg {
    cursor: pointer;
}
.delete_color {
    cursor: pointer;
}
.margin_left_auto {
    margin-left: auto !important;
}
.editable_table .rt-tr {
    cursor: pointer;
}
.header_content_box_value p > strong , .header_content_box_value ul li > strong , .header_content_box_value ol li > strong{
    font-family: 'arial';
}

.main_container_parent {
    width: 80%;
    float: left;
    height: 100%;
    overflow-y: scroll;
}
.w_90px {
    width: 120px;
}

/* close button  */

.sidebar_close {
    position: absolute;
    bottom: 0px;
    left: 20%;
    width: 40px;
    height: 40px;
    z-index: 100;
    cursor: pointer;
    border-top-right-radius: 4px;
    display: flex;
    align-items: center;
}
.sidebar_close svg {
    font-size: 20px;
}
.hide_close {
    display: none;
}
.width_100per_imp {
    width: 100% !important;
}
.left_zero {
    left: 0 !important;
}
.ReactTable .rt-resizer {
    z-index: 0 !important;
}
.bt-menu-trigger {
    font-size: 10px;
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    cursor: pointer;
    float: left;
    margin: auto;
  }
  
  .bt-menu-trigger span {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 100%;
    height: 0.2em;
    margin-top: -0.1em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: background-color 0.8s;
    transition: background-color 0.8s;
    -webkit-transform: translateY(-0.5em);
    transform: translateY(-0.5em);
    transform: rotate(45deg);
    transition: transform 0.8s;
    
  }
  .bt-menu-trigger.bt-menu-open span {
    -webkit-transform: translateY(-0.5em);
    transform: translateY(-0.5em);
    transform: rotate(0deg);
    transition: transform 0.8s;
  }
  .bt-menu-trigger span:after,
  .bt-menu-trigger span:before {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    -webkit-transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
  }
  
  .bt-menu-trigger span:before {
    -webkit-transform: translateY(-0.5em);
    transform: translateY(-0.5em);
    transform: rotate(-90deg);
  }
  
  .bt-menu-trigger span:after {
    -webkit-transform: translateY(0.5em);
    transform: translateY(0.5em);
    transform: rotate(90deg);
  }
  
  .bt-menu-trigger.bt-menu-open span:after {
    -webkit-transform: translateY(-0.36em) translateX(0.65em) rotate(45deg)
      scaleX(0.6);
    transform: translateY(-0.35em) translateX(0.65em) rotate(45deg) scaleX(0.6);
  }
  
  .bt-menu-trigger.bt-menu-open span:before {
    -webkit-transform: translateY(0.36em) translateX(0.65em) rotate(-45deg)
      scaleX(0.6);
    transform: translateY(0.35em) translateX(0.65em) rotate(-45deg) scaleX(0.6);
  }
    .sub_progress_bar {
        max-width: 900px;
        margin: 0 auto;
    }
    .sub_progress_table {
        height: calc(100vh - 395px);
    }
@media (max-width:991px) {
    .main_container_parent {
        width: 100%;
        height: auto;
        overflow:visible;
    }
    .overflow_hide {
        overflow: hidden;
        height: 100%;
    } 
}

.css-10nd86i + div > div:first-child {
    padding: 0px 8px!important;
}
.css-1wy0on6 > div {
	padding:5px 5px !important;
}

.css-10nd86i > div:first-child {
    height: 32px!important;
    cursor: pointer !important;
    min-height: auto !important;
}

.css-10nd86i > div:nth-child(2) {
    height: 32px!important;
    cursor: pointer !important;
    min-height: auto !important;
}

.css-10nd86i > div:nth-child(1) > div > div:nth-child(2) {
    margin:0 !important;
}
.css-10nd86i > div:nth-child(2) > div > div:nth-child(2) {
    margin:0 !important;
}
.ReactTable .rt-noData {
    z-index: 0 !important;
    color: black;
}

.css-1aya2g8 {
    width: 100% !important;
}
.css-1rtrksz {
    width: calc(100% - 100px);
}

.table_heading {
    padding: 7px 0px;
    text-align: center;
    border-bottom: 0px;
    /* font-weight: 700; */
    font-size: 14px;
    letter-spacing: 1px;
    /* border-top-left-radius: 8px;
    border-top-right-radius: 8px; */
    font-family: 'Proxima Nova Bold', sans-serif;
    position: relative;
}


@media (min-width:991px){
    .invalid.special_case {
        position: absolute;
        right: 0;
        font-size: 12px;
        bottom: -19px !important;
        top: unset !important;
    }
}
@media (max-width:768px){
    .margin_bottom_15_mob {
        margin-bottom: 15px;
    }
}
@media (min-width:768px){
    .table_scroll_container_routes .ReactTable .rt-tbody {
        max-height: calc(100vh - 255px);
    }
    .table_scroll_container .ReactTable .rt-tbody {
        max-height: calc(100vh - 255px);
    }
    .table_scroll_container_duration_course .ReactTable .rt-tbody {
        max-height: calc(100vh - 318px);
    }
    .structure_table_scroll .ReactTable .rt-tbody {
        max-height: calc(100vh - 268px);
    }
    /* .activities_table_scroll .ReactTable .rt-tbody {
        max-height: calc(100vh - 315px);
    } */
    .personal_table_scroll .ReactTable .rt-tbody {
        max-height: calc(100vh - 315px);
    }
    .personal_doctor_table_scroll .ReactTable .rt-tbody {
        max-height: calc(100vh - 305px);
    }
    .staff_table_scroll .ReactTable .rt-tbody {
        max-height: calc(100vh - 212px);
    }
    .medical_device_table .rt-tbody {
        max-height: calc(100vh - 424px);
    }
    .personal_table_scroll_last_item .ReactTable .rt-tbody {
        max-height: calc(100vh - 318px);
    }
    
    /*  performance  */

    .performance_table_scroll .rt-tbody {
        max-height: calc(100vh - 354px);
    }
    .performance_table_scroll.effect_table .rt-tbody {
        max-height: calc(100vh - 406px);
    }
    .performance_table_scroll.cost_of_intervention_table .rt-tbody {
        max-height: calc(100vh - 357px);
    }
    .performance_table_scroll.activity_interventions_table .rt-tbody {
        max-height: calc(100vh - 249px);
    }
    /* .consumable_definition_table_scroll .rt-tbody {
        max-height: calc(100vh - 278px);
    }
    .consumable_dci_table_scroll .rt-tbody {
        max-height: calc(100vh - 312px);
    } */
    .preparation_infusion_scroll .rt-tbody {
        max-height: calc(100vh - 443px);
    }

    /* importation */
    
    .import_react_table .rt-tbody {
        height:calc(100vh - 352px);
    }


}

.invalid_right {
    position: absolute;
    right: 0px;
    top: 3px;
    font-size: 12px;
}
.word_break_td thead th , .word_break_td tbody td {
    word-break: break-all;
    word-break: break-word;
}
.justify-content-center {
    justify-content: center;
}

.virtualScrollWrapper{
    position: relative;
}

.virtualScrollBar{
    position: absolute;width: 30px;
    height: calc(100vh - 230px);
    background: rgba(252, 252, 252, 0);
    right: 0px;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 999;
}
.mom_icon{padding-top: 7px;}
.ReactVirtualized_rt_table_height .rt-tbody
{
scrollbar-color: #f0f0f000 #fefeff00;
scrollbar-width: thin;
}
.anticon-loading { color: rgb(93, 180, 231);}
.standard_carechart_height {  height: calc(100vh - 335px) !important; }

.virtualScrollWrapper .ReactVirtualized__Grid {
    padding-right: 30px;
}
.enableDisable {width: 100%; text-align: center; font-size: 16px;}
.enableDisable .enable { color: #258a25; }
.enableDisable .disable { color: #d40e0e; }

.ant-btn-loading .ant-btn-loading-icon { float: right; margin-left: 5px;}
.ant-btn-loading .ant-btn-loading-icon .anticon-loading { padding-right: 0px !important;}