.Administer_Personnel_li_width {
    float: left;
    width: 33.33%;
}
.Administer_Personnel_perent_li_width {
    width: calc(100% - 350px);
}
.invalid_check_box {
    position: absolute;
    left: 0;
    top: -27px;
    font-size: 12px;
}
.model_num_align {
    text-align: right;
    padding-right: 45px;
    font-size: 13px;
}
.model_clear_filter {
 
    text-decoration: underline;
    margin-top: 7px;
    display: inline-block;
    font-size: 12px;
    color: black;
}
.filter_icon {
    font-size: 23px;
    cursor: pointer;
    position: relative;
    top: 4px;
}
.filter_icon.active {
    color: #0e38e4;
}
.ant_body_padding .ant-modal-body {
    padding-top: 0px;
}
.height_26 {
    height: 26px;
}
.height_250 {
    height: 250px;
}
.height_max_200 {
    max-height: 200px;
}
.height_max_255 {
    max-height: 255px;
}
.height_max_315 {
    max-height: 315px;
}
.height_max_355 {
    max-height: 355px;
}
.scroll{
    overflow: scroll;
}
.filter_label {
    color: black;
    font-size: 12px;
    font-weight: 100;
}
.model_clear_filter:hover {
    color: rgb(36, 35, 35);
}
.space_filter .col-md-6 {
    margin-bottom: 10px;
}
.Assign_template_treatment_table .rt-tbody  {
    height: calc(100vh - 460px);
}
.Assign_template_treatment_table .rt-thead .rt-tr:nth-child(1) .rt-th:nth-child(1) {
    visibility: hidden;
}

.Assign_template_treatment_table_fcell .rt-tbody  {
    height: calc(100vh - 460px);
}
.disable_up_svg svg {
    color: black !important;
}
.disable_up_svg {
    background-color: #eee !important;
}
.min_w_34 {
    min-width: 34px;   
}
.clinical_pathway_administration_container {
    height: calc(100vh - 66px);
}
.clinical_pathway_administration_table_tab_container {
    height: calc(100% - 100px);
}
.clinical_pathway_administration_table_tab_container .tab-content {
    height: calc(100% - 45px);
}
.clinical_pathway_administration_table_tab_container .tab-content .tab-pane {
    height: 100%;
    position: relative;
    overflow: auto;
}
.clinical_pathway_administration_table_tab_container .tab-content .tab-pane .ant-table-bordered .ant-table-thead > tr > th {
    position: sticky;
    top: 0;
    z-index: 10;
    border-top: 1px solid #e8e8e8;
}
.clinical_pathway_administration_table_tab_container .tab-content .tab-pane .ant-table-bordered table{
    border-top: none;
}
.height_300_editor .editor-class .public-DraftEditor-content{
    height: 300px;
}
.height_200_editor .editor-class .public-DraftEditor-content{
    height: 200px;
}
.clinical_pathway_list_table .sub-header td {
    position: sticky;
    top: 0;
    z-index: 99;
}
.clinical_pathway_administration_table_tab_container .tab-content .tab-pane .clinical_pathway_list_table .ant-table-bordered .ant-table-thead > tr > th {
    top: 21px;
}
.clinical_pathway_administration_table_tab_container .tab-content .tab-pane .clinical_pathway_list_table {
    border-collapse: separate;
    border-spacing: 0;
}