.model-notification{
    padding: 10px;
    float: left;
    border-radius: 5px;
    background-color: #f0ba61;
    color: #804c0a;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    -webkit-box-shadow: 0px 0px 4px 1px rgba(201,201,201,1);
    -moz-box-shadow: 0px 0px 4px 1px rgba(201,201,201,1);
    box-shadow: 0px 0px 4px 1px rgba(201,201,201,1);
}

.model-notification span{
    float: right;
    text-align: left;
}
.model-notification span button {
    color: black;
    margin-right: 20px;
}
.model-notification span a{
    color: blue;
    text-decoration: underline;
    margin-right: 7px;
}

.close {
    cursor: pointer;
    font-size: 12px;
    color: rgb(0, 0, 0);
    font-weight: 700px;
    margin-top: 10px;
  }

  .problem_pathway_section .mode-notification-enabled {
    height:calc(100% - 35px);
    overflow-y: scroll;
}
.rank_checkbox span {padding-left: 0px;}
.rank_checkbox .ant-checkbox {
  padding-right: 0px;
  margin-top: 3px;
}