

.css-rsyb7x input {
    width: 100% !important;
}
.css-rsyb7x {
    width: 100%;
}
.css-rsyb7x div {
    width: 100% !important;
}
.public-DraftStyleDefault-block {
    margin: 0;
}
.image_scroll_box::-webkit-scrollbar {
    -webkit-appearance: none;
    display: block;
}
.image_scroll_box::-webkit-scrollbar:horizontal {
    height: 11px;
}

.image_scroll_box::-webkit-scrollbar-thumb {
    border-radius: 8px;
}
.image_scroll_box::-webkit-scrollbar-track { 
    border-radius: 8px; 
}

/* scroll bar for react table */

.ReactTable .rt-table::-webkit-scrollbar {
    -webkit-appearance: none;
    display: block;
}
.ReactTable .rt-table::-webkit-scrollbar:horizontal {
    height: 11px;
}

.ReactTable .rt-table::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #616263 !important;
    display: block !important;
    width: 5px !important; 
    height: 5px !important; 

}
.ReactTable .rt-table::-webkit-scrollbar-thumb {
    border: 0px solid white;
}
.ReactTable .rt-table::-webkit-scrollbar-track { 
    border-radius: 8px; 
}

.overview_container {
    width: 100%;
    height: calc(100% - 66px);
}

.main_overview_section {
    width: 100%;
    float: left;
    height: 100%;
    padding-left: 15px;
    padding-right:15px;
}
.search_input {
    position: relative;
}

.search_input input{
    padding-right: 36px;
}

.search_input svg{
    position: absolute;
    top:10px;
    right: 10px;
    cursor: pointer;
}

.search_input::placeholder {
    font-size: 1.2em;
    font-style: italic;
  }

.record_container {
    display: inline-flex;
    width: 100%;
}

.record_container span {
    font-size: 14px;
}

.record_container b {
    font-size: 14px;
    margin-left: 5px;
}

.number_of_records {
    min-width: 155px;
}
.mt10_mobile {
    margin-top: 2px;
}
.filter {
    display: inline-block;
    margin-right: 30px;
}

.filter_icon{
    font-size: 23px;
    cursor: pointer;
    position: relative;
    top: 4px;
}

.label_select_container .label_name{
    font-size: 14px;
    display: inline-block;
}
.min_width_spl {
    min-width: 70px;
}
.min_width_costcenter_type {
    min-width: 86px;
}
.min_width_fmly {
    min-width: 57px;
}
.min_width_status {
    min-width: 50px;
}
.clear_filter_a{
    text-decoration: underline;
    margin-top: 7px;
    display: inline-block;
    font-size: 12px;
}

.clear_filter_a:hover {
    text-decoration: underline;
}

.tab_section {
    
}
.tab_section .nav-tabs {
    border-bottom:0px;
}
.tab_section .border_bottom_nav_tab {
    border-bottom: 1px solid #ddd;
}
.tab_search {
    border:none !important;
}
.tab_search:hover {
    border: none;
}
.tab_search input {
    width: 210px;
}
.emphasized { font-style: italic; }
.float_right_li {
    float:right !important;
}
.display-inline-flex {
    display: inline-flex;
}
.w-300-px {
    width: 300px;
}
.reactTableHeight {
    max-height: calc(100vh - 225px);
}
.ReactTableUserHeight {
    height: calc(100vh - 186px);
}
.ReactTableConsummableHeight {
    max-height: calc(100vh - 277px);
}
.invalidMsgMTL{
    position: absolute;
    right: 16px;
    font-size: 12px;
}
@media (min-width: 1200px){
    .margin_right_main_overview {
        padding-right:0px;
    }
}
@media (max-width: 991px){
    .tab_search input {
        width: 200px;
    }
    .overview_container {
        /* height:auto; */
    }
    .position_fixed_mobile {
        position:fixed;
    }
    
}
@media (min-width: 768px){
    .anesthesia_search {
        padding-bottom:7px;
    }
}
@media (max-width: 767px){
    .tab_search input {
        margin: 10px 0px;
        width: 100%;

    }
    .tab_section .nav-tabs li a {
        padding-left:10px;
        padding-right: 10px;
        margin-right: 0px;
    }
    .mt10_mobile {
        margin-top: 20px;
    }
    .min_width_spl {
        min-width: 75px;
    }
    .min_width_fmly {
        min-width: 75px;
    }
    .min_width_status {
        min-width: 75px;
    }
    .margin_bottom_20mobile {
        margin-bottom: 20px;
    }
}