.rdw-editor-main h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0px;
}
.public-DraftEditor-content {
    margin: 0px;
    padding: 10px 15px;
    min-height: 100px;
}

.toolbar-class .rdw-link-wrapper .rdw-link-modal {
    min-height: 236px;
}
.toolbar-class .rdw-link-wrapper .rdw-link-modal .rdw-link-modal-btn , .toolbar-class .rdw-link-wrapper .rdw-link-modal .rdw-link-modal-label , .rdw-link-modal-target-option{
    font-size: 12px !important;
}
.toolbar-class .rdw-link-wrapper .rdw-link-modal input{
    font-size: 12px;
    border-color: #e0e0e0;
}
.editor-class .public-DraftEditor-content {
    height: 100px;
    overflow-y: scroll;
}