.shap-bar-section .ant-collapse {
    position: absolute; 
    z-index: 9;  width: 250px; 
    margin-left: -10px;
    -webkit-box-shadow: 10px 10px 10px -10px rgba(96,102,143,1);
    -moz-box-shadow: 10px 10px 10px -10px rgba(96,102,143,1);
    box-shadow: 10px 10px 10px -10px rgba(96,102,143,1);
}
.ant-collapse-content>.ant-collapse-content-box { padding: 0px !important; }
/* .sb-mobile-palette { position: absolute; z-index: 9;} */
.ant-collapse-expand-icon { 
    /* position: absolute;  */
    right: 0px;
}
/* .ant-collapse>.ant-collapse-item:last-child, .ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header { padding: 6px 7px; border: 0px; } */
.e-accordion {border: none;}
.floating-button {position: absolute; z-index: 9;  width: 350px; right: 0px;}
.settings_container {width: 350px;}
.sb-mobile-diagram { width: 98.5vw !important;}
.sidebar_close_diagram { 
    position: absolute;
    bottom: 0px;
    width: 40px;
    height: 40px;
    z-index: 100;
    cursor: pointer;
    border-top-right-radius: 4px;
    display: flex;
    align-items: center;
    right: 349px;
    background: #023244;
    color: #fff;
    font-size: 20px;
    border-radius: 4px 0px 0px 0px !important; margin-left: -41px !important;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
}

.slide-side-bar {margin-right: -350px;  transition: all 0.3s ease; position: fixed;}
.svg_color_gray li svg { stroke: #3b3838; }
#diagram_contextMenu_grouping { display: none !important;}

.diagram_zoom_slider_closeButton {
    width: 16px;
    height: 14px;
    border: 1px solid #3740f2;
    text-align: center;
    vertical-align: middle;
    display: inline-table;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 9;
    cursor: pointer;
    background: #3740f2;
    color: #fff;
    /* cursor: all-scroll; */
}
