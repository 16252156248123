/* .attach_model_wrapper{
    width: 401px;

} */
.attach_model_wrapper .list{
    float: left;
}
.attach_model_wrapper .action{
    width: auto;
    float: left;
}
.attach_model_wrapper .ant-btn{

    height: 36px;
    border-radius: 0px 4px 4px 0px !important;
    
}
.attach_model_wrapper .css-1r4vtzz{
    border-radius: 4px 0px 0px 4px;
}

/* .react_multi_select_checkbox > div > div:nth-of-type(1){
    width: 450px !important;
} */


.attach_model_wrapper .action a{
    text-decoration: underline;
}

.model_modal_table .ReactTable {
    max-height: calc(100vh - 350px);
}
.clp_model_modal .ant-modal-body{
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.btn-tab-group  button {
    width: auto !important;
    border: 1px solid #ddd; ;
    color: #000;
    background: #f3f4f8;
    border-bottom: 3px solid #f3f4f8;
}
.btn-tab-group  button:hover{
    position: relative;
    border-bottom: 3px solid #3740f2;
    background-color: #f3f4f8 !important;
}

.btn-tab-group .active-tab-btton{
    position: relative;
    border-bottom: 3px solid #3740f2;
    background-color: #f3f4f8 !important;
    width: auto !important;
}
.marging-bottom--1{
    margin-bottom: -1px;
}
.linked_check_box .ant-checkbox-checked .ant-checkbox-inner{
background-color: #8a8a8e;
border-color: #6b6b73;
}
.linked_check_box .ant-checkbox-checked .ant-checkbox-inner:hover{border-color: #4d4d4d !important;}
.blue_text{
    color: blue !important;
}
.zIndex9X5 { z-index: 99999;}