.color_picker_popover {
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.custom_bordered_div {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}
.cursor_pointer {
    cursor: pointer;
}
.custom_bordered_div.style_applied {
    outline: 2px solid #bebebe;
}
.select_intervention .css-bgvzuu-indicatorSeparator {
    visibility: hidden;
}
.select_intervention .css-1g6gooi input{
    border: 0px !important;
}
.select_intervention .css-1hwfws3 {
    /* height: 32px; */
}
.select_intervention .css-dgoi55-control {
    max-height: 33px !important;
}
.select_intervention .css-1hwfws3 {
    max-height: 33px !important;
}
.select_intervention .css-azozfy-control .css-151xaom-placeholder, .select_intervention .css-dvua67-singleValue {
    top: 20px !important;
}
.diagram_route_link {
    color: #40a9ff !important;
    text-decoration: underline !important;
}
.diagram_route_link:hover {  color: #40a9ff !important; }
/* ========================================= */
.e-tooltip-wrap {
    border-radius: 4px;
    opacity: 1;
}
.e-tooltip-wrap.e-popup {
    background-color: #fff;
    border: 2px solid #000;
}

.e-tooltip-wrap .e-tip-content {
    color: black;
    font-size: 12px;
    line-height: 20px;
}

.e-tooltip-wrap .e-arrow-tip.e-tip-bottom {
    height: 12px;
    left: 50%;
    top: 100%;
    width: 24px;
}

/* To customize the arrow tip at top */
.e-tooltip-wrap .e-arrow-tip.e-tip-top {
    height: 12px;
    left: 50%;
    top: -9px;
    width: 24px;
}

/* To customize the arrow tip at left */
.e-tooltip-wrap .e-arrow-tip.e-tip-left {
    height: 24px;
    left: -9px;
    top: 48%;
    width: 12px;
}

/* To customize the arrow tip at right */
.e-tooltip-wrap .e-arrow-tip.e-tip-right {
    height: 24px;
    left: 100%;
    top: 50%;
    width: 12px;
}

.e-tooltip-wrap .e-arrow-tip-inner.e-tip-bottom {
    color: #fff;
    font-size: 22.9px;
}

/* To customize the arrow tip at bottom */
.e-tooltip-wrap .e-arrow-tip-outer.e-tip-bottom {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #000;
}
.problem_type_drop_down {
    width: 100%;
    border-radius: 2px;
}

.mom-date-picker .rc-editable-input-5 {width: 100% !important;}

.all-scroll {cursor: all-scroll;}