.MissLink-Icons-Holder {
    width: 33px;
    float: left;
    cursor: pointer;
}
.red_icon svg{
    stroke: rgb(240, 27, 27);
}
.red_icon svg rect {
    stroke: rgb(240, 27, 27);
}