.text_area_treatment {
    height: 114px !important;
    resize: none;
}
.scaned_label_header {
    color: #3E3E3E;
    font-size: 17px;
  
}
.model_content_color {
    color: #3c3c3c;
}
.scaned_primary_color_head {
    border: 1px solid #dddddd;
    padding: 10px 15px;
    border-radius: 5px;
    margin: 5px 0px;
}
.scaned_primary_header {
    font-size: 17px;
}
.romove_link_btn_scan {
    text-align: right;
    padding-top: 35px;
}
.other_sugections {
    padding-top: 10px;
    color: #070707;
    font-size: 15px;
}
.scanned_search_model input {
    display: block;
    width: 100%;
    height: 32px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
}
.button_div {
    display: inline-block;
    vertical-align: top;
    width: 120px;
    padding: 0px 10px;
}
.button_div_else {
    display: inline-block;
    vertical-align: top;
    width:calc(100vh - 120px);
    padding: 0px 10px;
}
.mt-25{
    margin-top: 25px;
}
.d_flex {
    display: flex;
}
.align_items_base {
    align-items: flex-end;
}