.virtualScroll-diagram-H {
    overflow-x: scroll;
    background-color: rgb(0, 0, 0);
    z-index: 999;
    border-radius: 10px;
    bottom: 5px;
    position: absolute;
    height: 12px;
}
.virtualScroll-diagram-H div {
    float: left;
    width: 100%;
    height: 3px;
}