.patient_info_drop_down.btn-group{
    display: inline-flex !important;
    float: left;
}

.patient_info_drop_down .btn-group{
    display: inline-flex !important;
}


.subsidy_bold {
    font-weight: bold;
    font-family: 'Proxima Nova Bold';
  }

.patient_info_drop_down button{
    font-size: 12px;
    border-radius: 4px;
    padding: 4px 13px;
    outline: none !important;
    box-shadow: none!important;
    text-transform: uppercase;
}

.intervention_name {
    font-size: 18px;
    font-family: 'Proxima Nova Bold', sans-serif;
}

.intervention_speciality_name {
    font-size: 15px;
    font-family: 'Proxima Nova Semibold', sans-serif;
    color: black;
    margin-left: 10px;
}


.intervention_details .id_total_section {
    padding-right: 60px;
}

.intervention_details .id_total_section span {
    display: inline-block;
}

.intervention_details .id_total_section * {
    font-size: 18px;
}

.intervention_details .rt-th *, .intervention_details .rt-tfoot * {
    font-family: 'Proxima Nova Bold', sans-serif;
}

.mr-20 {
    margin-right: 20px;
}


.common_button.pumpkin {
    font-weight: 700;
    border-radius: 0;
    padding: 7px 60px;
}

.patient_info_drop_down .drop_button{
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}
.patient_info_drop_down .text_button{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-right: 0px !important;
    min-width: 40px;
    cursor: pointer;
}

.patient_info_drop_down button i{
    margin-right: 5px;
    font-size: 12px!important;
}
.patient_info_drop_down button .caret_down{
    margin-right: 0px;
    font-size: 15px!important;
    position: relative;
    top: 2px;
}

.patient_info_drop_down .dropdown-menu{
    padding: 0;
}
.patient_info_drop_down .dropdown-menu a{
    padding: 5px 15px;
    font-size: 12px;
}

.patient_info_drop_down .dropdown-menu a i{
    padding-right: 5px;
    vertical-align: text-top;
}
.patient_info_drop_down .dropdown-menu a svg{
    vertical-align: sub;
    width: 11px;
    font-size: 15px;
}
.patient_info_drop_down .dropdown-menu a img{
    margin-right:10px;
    width: 11px;
    vertical-align: sub;
}
.btn-group button.text_button:hover { 
    cursor: pointer;
}
.btn-group button.text_button.eye {
    padding: 4px !important;
}
.btn-group button.text_button.eye:hover { 
    cursor: pointer;
}
.btn-group button.text_button.eye img {
    width: 13px;
}

.eye_button_section {
    position: sticky;
    top: 0;
    z-index: 99;
    width: calc(100% + 30px);
    margin-left: -15px;
    padding: 0px 15px;
}
.intervention_header .mb-10{
    margin-bottom: 10px;
}

.header_content_box_title{
    padding:5px 10px;
    font-family: 'Proxima Nova Bold', sans-serif;
}
.header_content_box_value{
    padding:5px 10px;
    min-height: 70px;
    word-wrap: break-word;
}
.header_content_box_value ul, .header_content_box_value ol {
    margin-left: 15px;
    margin-top: 16px;
    margin-bottom: 16px;
}
.component_heading {
    font-size: 15px;
    font-weight: 600;
}

.header_copy_paste {
    text-align: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.subcomponent_table .rt-tr .rt-th:first-child { 
    display: none;
}
.subcomponent_table .ReactTable .rt-tr .rt-th:first-child { 
    display: block;
}
.toast_div {
    padding: 15px;
    font-size: 13px;
    position: absolute;
    z-index: 98;
    position: relative;
    margin-left: -15px;
    width: calc(100% + 30px);
    z-index: 98;
}
.toast_div button {
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
}
.special_float_right {
    float: right !important;
}
.cost_modal_check .ant-modal-body {
    position: relative;
}
.cost_modal_check .invalid {
    position: absolute;
    right: 24px;
    top: 25px;
    font-size: 12px;
}
.cost_modal_cost_screen {
    width: 87% !important;
    top: 30px !important;
}
.cost_table_section{
    height: 350px;
}
.cost_table_section .ReactTable {
    height: 350px;
}
.cost_table_section .ReactTable .rt-table{
    height: 100%;
}
.disabled_btn {
    color: #a0a0a0;
    background-color: #ccc;
    pointer-events: none;
}
.prblm_search {
    margin-top:6px;
}
.select_prbl_section .ant-select-selection {
    min-width:155px;
    margin-top:6px;
}
.padding_left_prblm {
    padding-left: 10px;
}
.select_task_pathway {
    min-width: 155px;
    margin-top:6px;
}
.pathway_task {
    margin-top: 4px;
    margin-left: 10px;
    margin-right:10px;
    overflow: hidden;
}
.pathway_search {
    margin-top: 6px;
}
.pathway_li {
    margin-bottom: 0 !important;
}
.sythesis_modal_table  {
    max-height: 300px;
    overflow: auto;
    border-bottom:1px solid rgba(0, 0, 0, 0.1);
}
.sythesis_modal_table table {
    border-collapse: separate;
    border-spacing: 0px;
    border-top:0px;
    border-bottom:0px;
}
.sythesis_modal_table table tr {
    border-bottom: none;
}
.sythesis_modal_table table tr td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05) ;
}
.sythesis_modal_table table tr th {
    position:sticky;
    top: 0;
    border-top:1px solid rgba(0, 0, 0, 0.1);
}
.radio_grp_container span{
    font-size: 12px;
}
.pblm_btn_container {
    
}
.pblm_btn_container .tool_tip .anticon-info-circle {
    font-size: 25px;
    margin-right: 5px;
    margin-top: 3px;
}
.pathway_form_btn .item_invalid {
    top:-15px !important;
    left:15px;
    font-size: 10px;
}
.pathway_form_btn .invalid_right {
    left:5px;
}
.pathway_form_btn .invalid_left_container {
    top: -54px !important;
    float: right;
    margin-right: 15px;
    z-index: 99;
    position: relative;
}
.txtarea_btn_container .text_area_treatment {
    height: 75px !important;
}
.task_btn_container .ant-btn {
    padding-right:8px;
}
.task_btn_container .ant-btn .anticon-caret-down{
    margin-right:0px;
    padding-left: 5px;
}
@media (max-width:767px) {
    .copy_section .mr-20 {
        margin-right: 0px;
        margin-top: 10px !important;
    }
    .copy_section {
        display: grid !important;
    }
    .copy_section .mb-10{
        margin:auto;
    }
    .copy_button {
        min-width: 195px !important;
        text-align: left;
    }
    .intervention_details .id_total_section {
        padding-right: 0px;
    }
    
    .intervention_details .id_total_section span {
        display: inline-block;
        min-width: auto;
        margin-left: 0px;
    }
    
    .intervention_details .id_total_section * {
        font-size: 16px;
    }
    .margin_top_24px_special {
        margin-top: 24px;
    }

}
.copy_button {
    border: none !important;
    box-shadow: none !important;
}
.word_break {
    word-break: break-all;
    word-break: break-word;
    white-space: normal;
    width: 100%;
}
@media (max-width:767px) {
    .intervention_header .mb-10{
        margin-bottom: 10px;
    }
}
@media (max-width:991px) {
    .intervention_header {
        margin-top: 10px;
    }
}
.image_scroll_box {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    height: auto;
    display: inline-block;
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
    width: 100%;
}

.image_scroll_box .image-box img {
    cursor: pointer;
}

.image_scroll_box .image-box {
    width: 300px;
    display: inline-block;
    position: relative;
    padding: 20px;
    margin-right: 15px;
}

.validated {
    color: white !important;
    background-color: #3fa918;
    border: 1px solid #3fa918 !important;
}

.validated:hover {
    color: white !important;
    background-color: #369614;
    border: 1px solid #369614 !important;
}

.not_validated {
    color: white !important;
    background-color: #a23923;
    border: 1px solid #a23923 !important;
}

.not_validated:hover {
    color: white !important;
    background-color: #913422;
    border: 1px solid #913422 !important;
}

.recommend_btn_green > button {
    background-color: #369614 !important;
    border: 1px solid #369614 !important;
}
body .intervention_header .recommend_btn_green .scanned_intervention_sub_btn:hover {
    background-color: #369614 !important;
    border: 1px solid #0e38e4 !important;
    border-right: none !important;
}
body .intervention_header .recommend_btn_red .scanned_intervention_sub_btn:hover {
    background-color: #a23923 !important;
    border: 1px solid #0e38e4 !important;
    border-right: none !important;
}
.recommend_btn_red > button {
    background-color: #a23923 !important;
    border: 1px solid #a23923 !important;
}

.recommend_btn_green > div, .recommend_btn_red > div {
    box-shadow: 5px 5px 10px #ddd !important;
}

.recommend_btn_green svg path, .recommend_btn_red svg path {
    fill: #ffffff !important;
    stroke: #ffffff !important;
}

.color-3740f2{
    color: #3740f2 !important;
}

.btn-group .left-button{
    position: relative;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-width: 135px;
}

.btn-group .right-button {
    position: relative;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-group .left_button {
    position: relative;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.btn-group .center_btn {
    border-radius: 0 0 0 0;
}

.btn-group .active-btton, .btn-group .left_button:hover, .btn-group .right-button:hover , .btn-group .center_btn:hover, .btn-group .single-button:hover {
    color: #fff;
    background: #3740f2;
    position: relative;
}

.btn-group .left-button input[type=checkbox], .btn-group .right-button input[type=checkbox]{
    position: absolute;
    top: 4px;
    left: 5px;
}
.mr-10{
    margin-right: 10px;
}

.mr-7{
    margin-right: 7px;
}
.uppercase{
    text-transform: uppercase;
}

.bg-intervention-details{
    background-color: #d4d4d4d5;
}
.ex_width_160 {
    width: 160px;
}
.justify_content_between {
    justify-content: space-between;
}
.common_input input {
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 0px 10px;
    height: 32px;
    outline: none;
    box-shadow: none;
    color: #000000;
}
.width_85 {
    width: 85px;
}
.pl-25{
    padding-left: 25px;
}
/* .copy_scroll{
    height: 250px;
    overflow: auto;
} */
.copy_scroll .ReactTable .rt-tbody{
    max-height: 200px;
    overflow: auto;
} 
.min_width_90 {
    min-width: 90px !important;
}
.min_width_75 {
    min-width: 75px !important;
}
.pathway_form_btn  .validation_argumentation {
    top : 96px !important;
}
.pathway_form_btn  .validation_risk {
    top : 54px !important;
}
.clinical_pathway_container {
    height: calc(100% - 40px) !important;
}
.hospital_text {
    font-size: 15px;
    color:  #3740f2 !important;
    height: 27px;
    overflow: hidden;
}
.copy_btn_modal {
    display: flex;
    justify-content: flex-end;
}
/* .pathway_tab {
    position: sticky;
    top: 80px;
    z-index: 99;
    background-color: #ffffff;
}
.pathway_sign_complication {
    position: sticky;
    top: 124px;
    z-index: 99;
    padding-top: 15px;
    background-color: #ffffff;
} */
.pathway_sign_complication {
    padding-top: 15px;
}
/* .pathway_problem_table {
    height: calc(100vh - 475px);
    overflow: scroll !important;
    overflow-x: hidden;
}
.pathway_problem_table_sign {
    height: calc(100vh - 365px);
    overflow: scroll !important;
    overflow-x: hidden;
}
.pathway_problem_table .ReactTable .rt-table .rt-tbody{
    max-height: calc(100vh - 545px);    
}
.pathway_problem_table_sign .ReactTable .rt-table .rt-tbody{
    max-height: calc(100vh - 445px);    
} */
.pathway_scrollable_content {
    height: calc(100vh - 255px);
    overflow: scroll;
    overflow-x: hidden;
    position: relative;
}
.task_label {
    color: #3740f2 !important;
}
.pathway_code {
    width: 150px;
}
.pathway_hospital {
    width: 200px;
    padding-left: 10px;    
}
.pathway_code_btn {
    display: flex;
    width: calc(100% - 380px);
    justify-content: flex-end;
    padding-right: 15px;    
}
.collaps_data .ant-collapse-header{
    background-color: #0477bd;
    color: #ffffff !important;
}
.collapse_sub .ant-collapse-header{
    background-color: #fafafa;
    color: #000000 !important;
}
.collapse_sub .subStep_heading {
    color: #0477bd !important;
}
.collapse_sub .ant-table-empty .ant-table-body {
    overflow-x: hidden !important;
}
.select_problem_edit .css-kj6f9i-menu {
    z-index: 9; 
}
.sub_step_container .ant-table-body {
    min-height: 50px; 
    max-height: calc(100vh - 422px);
    overflow-y: scroll;
}
.full_screen .sub_step_container .ant-table-body {
    min-height: 200px; 
    max-height: calc(100vh - 300px);
    overflow-y: scroll;
}
.sub_step_container .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 9;
}
/* .drobdown_select_data {
    background-color: red !important;
} */
.switch_container_pathway .ant-switch {
    min-width: 30px !important;
}
.empty_step_hidden {
    font-weight: 500;
    color: #FF0000;
    height: 15px;
    position: absolute;
    top: -5px;
}
.rdw-link-modal .rdw-link-modal-buttonsection .rdw-link-modal-btn {
    width: auto !important;
    
}
.invalid_select_pathway {
    right: 0px !important;
}
.invalid_select_component {
    top: -34px !important;
}
.ant-table-thead tr th {
    padding: 6px;
}
.ant-table-tbody tr td{
    padding:6px;
}

.mt4 {
    background-color: #f5f5f5;
    width: 100%;
    min-height: 297mm;
    margin-left: auto;
    margin-right: auto;
    float: left;
}
.scroll_disabled{
    display: inline;
    overflow: visible;
    height: auto;
}
.steps_label_special_copy_from_source {
    margin-top: 35px;
    display: inline-block;
    font-size: 12px;
    padding-top: 25px;
}

.diagram-reorder-button {
    right: 140px;
    position: absolute;
    content: "\f0c9";
    font-size: 20px !important;
}
.span-vertical-elips {
    color: white;
    font-size: 18px;
    cursor: pointer;
}  
.trajectory_total_number_of_items {
    font-weight: normal;
    font-family: 'Proxima Nova Semibold', sans-serif;
}
.table_heading { min-height: 35px;}
.HP_DETAILS_PAGE .table_heading {
    border: 0px !important;
}
.HP_DETAILS_PAGE .table_heading {
    border: 0px !important;
}
.healthpathway_container {
    height: calc(100% - 15px) !important;
    overflow: hidden !important;
}
.top_section_activity_bar {
    width: 100%;
    height: 42px;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}