.import_file_title {
    margin-top: -30px;
    font-size: larger;
    margin-left: 45%;
    font-weight: bolder !important;
}
.vertical_align_text_bottom {
    vertical-align: text-bottom;
}
.parcours_marges_filter {
    width: 65px;
    height: 24px;
    border-radius:4px;
    border:1px solid #a79d9d;
    color: black;
}
.parcours_marges_filter:focus {
    outline: none;
}
.parcours_filter_numeric_input {
    display: inline-block;

}
.parcours_marges_svg svg {
    width:25px;
    height:10px;
}
.analysis_parcours_percent_filter {
    float: right !important;
}
.parcours_marges_svg {
    display:inline-block;
}
.vertical_align_middile {
    vertical-align:middle;
}
.vertical_align_bottom {
    vertical-align:bottom;
}
/* .table_parcours_with_filter_margins  .rt-table {
    max-height: calc(100vh - 333px);
} */
.table_parcours_with_filter_margins .rt-tbody {
    height: calc(100vh - 455px);
}
.table_parcours_with_filter .rt-tbody {
    height: calc(100vh - 390px);
}
.parcours_filter_section .css-10nd86i > div:first-child {
    height: 27px !important;

}
.parcours_filter_section .css-10nd86i > div:nth-child(2) {
    height: 27px !important;

}
.parcours_filter_section .css-1wy0on6 {
    height: 25px !important;
}
.parcours_filter_section .css-1wy0on6 > div {
    padding: 3px 5px !important;
}
.parcours_filter_section .ant-calendar-picker-input {
    height: 27px !important;
    padding: 0px 11px !important;
}
.parcours_filter_btn_active {
    border: 1px solid  ;
    border-radius: 5000px;
    padding: 3px 13px 2px 13px;
    font-size: inherit;
    background-color: white;
    font-size: 10px;

}
.parcours_filter_btn_active_left {
    border: 1px solid  ;
    border-radius: 4px 5000px 5000px 4px;
    padding: 3px 13px 2px 13px;
    font-size: inherit;
    background-color: white;
    font-size: 10px;
}
.parcours_filter_btn_active_right {
    border: 1px solid  ;
    border-radius: 5000px 4px 4px 5000px;
    padding: 3px 13px 2px 13px;
    font-size: inherit;
    background-color: white;
    font-size: 10px;
}
.parcours_filter_btn {
   
  border: none;
  padding: 3px 13px 2px 13px;
    border-radius: 0;
    background-color: white;
    font-size: 10px;
    color: black;
    
}
.parcours_filter_btn_right {
    padding: 3px 13px 2px 13px;
    border-radius: 0;
    border: none;
    background-color: white;
    font-size: 10px;
    color: black;
}
.parcours_filter_btn_left {
    padding: 3px 13px 2px 13px;
    border-radius: 0;
    border: none;
    background-color: white;
    font-size: 10px;
    color: black;
}
.parcours_filter {
    border: 1px solid #a79d9d;
    color: #a79d9d;
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 5px;
    border-radius: 2px;    
}
.analysis_parcours_filter{
    border: 1px solid #a79d9d;
    color: #a79d9d;
    display: inline-block;    
    border-radius: 2px; 
}
.parcours_filter div {
    display: inline-block;
    vertical-align: top;
}
.analysis_parcours_filter div {
    display: inline-block;
    vertical-align: top;
}
.toggleClassSelected_left {
    border: 1px solid #a79d9d;
    color: #a79d9d;
    border-right: none;
    border-radius: 5px 0px 0px 5px !important;
    
}
.toggleClassSelected_inBtw {
    border: 1px solid #a79d9d;
    color: #a79d9d;
    border-left: none;
    border-right: none;
    border-radius: 0px 0px 0px 0px !important;
}
.toggleClassSelected_right {
    border: 1px solid #a79d9d;
    color: #a79d9d;
    border-left: none;
    border-radius: 0px 5px 5px 0px !important;
}
.text_button_parcoursleft {
    border: 1px solid blue;
    border-radius: 5px 0px 0px 5px !important;
    color: blue;
    min-height: 28px;
}
.text_button_parcours_inBtw {
    border: 1px solid blue;   
    border-radius: 0px 0px 0px 0px !important;
    color: blue;
    min-height: 28px;
}
.text_button_parcoursright {
    border: 1px solid blue;   
    border-radius: 0px 5px 5px 0px !important;
    color: blue;
    min-height: 28px;
}
.font-family-bold {
    font-family: 'Proxima Nova Bold', sans-serif;
}
.valuation_sejour {
    max-height: 500px !important;
    width: 85% !important;
}
.valuation_sejour .ant-modal-body {
    max-height: 420px !important;
}
.valuation_sejour  .ant-modal-body .ReactTable {
    max-height: 260px !important;
}
.table_footer_show  .rt-tbody {
    max-height: calc(100vh - 320px) !important;
}
@media only screen and (max-width: 1200px)  {
    .width-20-response {
        width: 17% !important;
    }
}
@media (max-width:1174px) {
    .parcours_serach_bar {
        width: 187px !important;
    }
}
.w_50_per {
    width: 50%;
}
.valuation_cost_modal {
    width: 85% !important;
    max-height: 400px;
}
.valuation_cost_modal .ant-modal-content {
    height: 100%;
}
.valuation_cost_modal .ant-modal-body {
    
    max-height: 320px;
}
.valuation_cost_modal .ant-modal-body .ReactTable {
    max-height: 200px;
    
}
.valuation_sejour .ReactTable .rt-thead .rt-resizable-header-content {
    text-overflow: unset !important;
    word-break: break-all;
    word-break: break-word;
    white-space: pre-wrap !important;
    display: flex;
    align-items: center;
}
.valuation_sejour  .ReactTable .rt-thead .rt-th.-cursor-pointer {
    min-height:  80px;
}
.valuation_cost_modal .ReactTable .rt-thead .rt-resizable-header-content {
    text-overflow: unset !important;
    word-break: break-all;
    word-break: break-word;
    white-space: pre-wrap !important;
    display: flex;
    align-items: center;
}
.valuation_cost_modal .ReactTable .rt-thead .rt-th.-cursor-pointer {
    min-height:  80px;
}
.tabsmobile_checkbox {
       color: black;
       font-size: 10px;
       cursor: default;
       
}
.w_60_per {
    width: 60% !important;
}
.width_spl_90 {
    width: 121px;
    word-break: break-all;
    word-break: break-word;
}
.margin-top-neg-4 {
    margin-top: -4px;
}
.tabsmobile_checkbox:hover {
    color: #5c5c5c;
    text-decoration: none;
}
.ReactTable .rt-th, .ReactTable .rt-td {
    padding: 5px 10px !important;
    display: grid;
}

.ReactTable .rt-td .parcours_footer_cost {
    margin-right: 0 !important;
    display: inherit;
}

.text_align_right {
    text-align: right;
}

.ReactTable .rt-th, .ReactTable .rt-td div{
    margin: auto;
    margin-left: 0;
}

.import_step_style {
    position: fixed;
    bottom: 0;
    right: 0;
    border-top: 1px solid #ddd;
    padding-top: 5px;
    background-color: white;
}

.pole_create_box label {
    font-size: 12px;
    margin-bottom: 8px;
}
.pole_create_box_clp label {
    font-size: 11px;
    margin-bottom: 8px;
}
.H_300px {
    height: 300px;
}
.overflow_visible {
    overflow: visible !important;
}
.staff_table_border {
    border:1px solid #ddd;
    padding: 15px;
}
.custom_tabs_container {
    display: inline-block;
    width: 100%;
    padding: 15px;
    border:1px solid #ddd;
    margin-bottom: 20px;
}
.custom_tabs_section {
    display: inline-block;
    width: 100%; 
}
.custom_tab {
    background-color: #fff;
    color: #5c5c5c;
    border: 1px solid #ddd;
    padding: 4px 15px;
    float: left;
    margin: 0px 10px 5px 0px;
    cursor: pointer;
    border-radius: 4px;
}
.custom_tab.active {
    background-color: #e0f1ff;
}
.float_right_imp {
    float: right !important;
}
.instrumentation_tab .tab-content {
    border-top: 0;
    padding: 20px;
}
.disabled_a_tag {
    cursor: default;
    pointer-events: none;
}
.assign_steps_react_table .rt-tbody{
    max-height: 300px;
    overflow:scroll;
}
.code_special_div {
    position: relative;
}
.red_round {
    width: 12px;
    height: 41px;
    display: inline-block;
    margin-right: 10px;
    position: absolute;
    left: -10px;
    top: -10px;
    cursor: pointer;
}

.red_round_normal_table {
    width: 15px;
    min-height: 15px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    top: 0;
    left: 0;
    flex-shrink: 0;
}

.round_normal_table{
    width: 15px;
    min-height: 15px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    top: 0;
    left: 0;
    flex-shrink: 0;
}

.excel_dropdown {
    min-width: 180px;
}
.pdf_dropdown {
    min-width: 165px;
}
.no_position_found {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pole_breadcrumb.with_records {
    display: inline-block;
    width: 100%;
}
.pole_breadcrumb.with_records .ant-breadcrumb{
    display: inline-block;
}

.pole_breadcrumb.with_records .record_container {
    display: inline-block;
    width: auto;
    float: right;
}

.modal-react-height .ReactTable {
    max-height: calc(100vh - 250px);
}

/* custom table */
.table_div table {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: none !important;
}
    
.table_div table, .table_div th, .table_div td {
    border-collapse: collapse;
    color: black;
    font-size: 14px;
}
.table_div td {
    padding: 7px 10px;
    font-size: 14px;
    border-right: 1px solid #dedede;
}
.table_div table th {
    background-color: #f3f4f8;
    padding: 7px 10px;
    text-align: center;
    border-right: 1px solid #dedede;
    font-family: 'Proxima Nova Bold', sans-serif;
}
.table_div table tr .sub_header{
    background-color: #edf7f4 !important;
    
}
.table_div table thead td {
    -webkit-box-shadow: inset 0 0 0 0 transparent;
    box-shadow: inset 0 0 0 0 transparent;
}
.table_div tr {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
    page-break-inside: avoid !important;
}

.table_div {
    /* margin-bottom: 20px; */
    overflow: auto;
}
.table_section {
    float: left;
}
.bg_ec {
    background-color: #f3f3f3;
    padding: 20px;
    margin-bottom: 20px;
}

.pdf_excel_dropdown .dropdown-menu {
    min-width: 116px !important;
}
.durations_tab {
    border: 1px solid #ddd;
    padding: 15px;
    border-bottom: 0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.course_buttons {
    text-align: right;
}
.min_width_spl_90 {
    min-width: 90px;
}
.total_cost_modal {
    text-align: right;
    padding: 0px 0px 10px;
}
.total_cost_modal span:first-child {
    color: #9b9b9b;
}
.total_cost_modal span:nth-child(2) {
    color: #707070;
    font-weight: 600;
}
.re_assign_modal .ant-btn{
    float: right;
    margin-left: 10px;
}
.re_assign_modal .ant-modal-footer {
    display: inline-block;
    width: 100%;;
}
.edit_clinical_pathway {
    width: 940px !important;
}
.edit_clinical_pathway .ant-modal-body {
    max-height: calc(100vh - 133px);
    overflow: scroll;
    min-height: 400px;
    /* height: 400px;
    overflow-y: scroll; */
    padding-top: 9px;
    padding-bottom: 9px;
}
.edit_clinical_pathway .ant-modal-body .text_area_treatment {
    height: 71px !important;
}
.edit_clinical_pathway .ant-modal-body .pole_create_box label {
    margin-bottom: 3px;
} 
.edit_clinical_pathway .ant-modal-body .item_invalid {
    top: -12px !important;
}
.edit_clinical_pathway .ant-modal-body .invalid_right_edit {
    top: -36px;
}
.ant-table{
    font-size: 12px !important;
}
.ant-collapse .ant-table .quick_edit_icon {
    width: 14px;
    height: 14px;
    margin-bottom: 5px;
}
.ant-collapse .ant-table .drag_icon {
    width: 14px;
    height: 14px;
    background-image: url(/public/images/reorder.png);
    background-size: 14px 14px;
    margin-bottom: 5px;
}
.ant-collapse .ant-table .textarea_quick_edit textarea {
    resize: none;
}
.max-width-353 {
    max-width: 354px;
}
.doctor_list_checkbox {
    min-width: 210px;
    display: inline-table;
}
@media (max-width:767px) {
    .course_buttons {
        text-align: unset;
    }
    .course_buttons .margin_top_15 {
        margin-top: 15px;
    } 
    .margin-top-15mob {
        margin-top: 15px;
    } 
    .mb_mob_15 {
        margin-bottom: 15px;
    }      
}
@media (max-width:442px) {
    .margin_top_15_mob {
        margin-top: 15px;
    }
}
.width-20-response {
    width: 20%;
}
.min-height-33 {
    min-height: 33px;
}
.min-height-36 {
    min-height: 36px;
}

.export_btn_parcous {
    padding: 4px 13px;
    background-color: white;
    border: 1px solid;
    border-radius: 4px;
}
.refresh_btn {
    padding: 4px 13px;
    background-color: #d40e0e !important;
    color: #ffff !important;
    border: 1px solid #d40e0e !important;
    border-radius: 4px;
}
.parcourse_table_height_filter .rt-table .rt-tbody {
    height: 325px !important;
}
.parcourse_table_height .rt-table .rt-tbody {
    height: 453px !important;

}
.group_sejour_table .ReactTable .rt-table .rt-tbody {
    max-height: calc(100vh - 300px);
}
.parcourse_checkbox {
    display: block;
    font-size: 12px;
    line-height: 12px;
}
.unlined_error_strip_1 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    width: 10px;
}
.unlined_error_strip_2 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 12px;
    width: 10px;
}
.unlined_error_strip_3 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 24px;
    width: 10px;
}
.unlined_error_strip_4 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 36px;
    width: 10px;
}
.unlinked_red {
    background-color: rgb(243, 69, 69) !important;
}
.unlinked_green {
    background-color: #258a25;
}
.unlined_white {
    background-color: #f3f4f8;
}
.recommend_std_strip {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10px;
    left: 55px;
}
.scanned_treatments_table .rt-thead .rt-tr .rt-th:nth-child(1) {
    padding: 5px 5px !important;
    color: #f3f4f8;
}
.scanned_treatments_table .rt-tbody .rt-tr-group .rt-tr .rt-td:nth-child(1) {
    padding: 5px 5px !important;
}
.intervention_table .rt-thead .rt-tr .rt-th:nth-child(1) {
    padding: 5px 5px !important;
    
}
.table_head_element_hide .rt-thead .rt-tr .rt-th:nth-child(1) {
    color: #f3f4f8;
}
.left-0 {
    left: 0;
}
.validated_strip {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10px;
    left: 43px;
    background-color: #258a25;
}
.filter_width_parcourse_section_tabs {
    display: inline-block;
    vertical-align: top;
    width: 522px;
}
.filter_width_parcourse_section {
    width: calc(100% - 522px);
    display: inline-block;
    vertical-align: top;
}
.parcours_filter_section .filter_width_parcourse_section .ant-calendar-picker-input {
    height: 23px !important;
    border-radius: 0px;
}
.scaned_treatments_clear_filter_position {
    position: absolute;
    top: -80px;
    right: 0px;
}
.ReactVirtualized_rt_table_height .rt-tbody {
    height: calc(100vh - 271px);
    max-height: initial !important;
}
.ReactVirtualized_rt_table_height {
    height: auto !important;
}
.invalid_right_patient_admition {
    position: absolute;
    right: 0px;
    top: -20px;
    font-size: 12px;
}
.rt-body-x_scroll-hidden .rt-tbody {
    overflow-x: hidden;
}
.parcourse_multu_select_scroll > div:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(1) {
    max-height: 55px;
    overflow: auto;
}
.check_container {
    display: inline-block;
    text-align: left;
    padding: 10px 50px 10px 13px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    min-width: 600px;
}
.check_position {
    position: absolute;
    right: -25px;
    top: 0;
}
.font_size_14 {
    font-size: 14px;
}
.flex_outer {
    display: flex;
    width: 100%;
    padding: 0px 15px;
    justify-content: flex-end;
}
.flex_items {
    flex: 1 1 200px;
    padding: 0px 5px 0px 0px;
    text-align: center;
}
.flex_items_1 {
    flex: 2 1 180px;
    padding: 0px 5px 0px 0px;
    text-align: center;
}
.height_29_min > div:nth-of-type(1) {
    height: 29px;
    min-height: 29px !important;
}
.height_29_min > div:nth-of-type(1) > div:nth-of-type(1) {
    height: 27px;
}
.width_100_px {
    width: 100px;
}
.width_200 {
    width: 200px;
}
.width_200_p {
    width: 200px;
    max-width: 200px;
}
.padding-right-10{
    padding-right: 10px;
}

.padding-right-20{
    padding-right: 20px;
}

.quicknav-table .quick_act_name{
    flex: none !important;
    word-break: break-all;
    width: 100% !important;
    white-space: break-spaces;
}

.dropdown-menu-headding{
    padding: 5px 10px;
}
.dropdown-menu-headding span{
    float: left;
    width: 100%;
    border-bottom: 1px solid #0477bd;
    color: #0477bd;
    font-size: 11px;
}

.position-relative{
    position: relative;
}

.intervention-clear-filter{
    position: absolute;
    right: 15px;
    z-index: 999;
}
.mr-5{
    margin-right: 5px;
}

.padding-top-7{
    padding-top: 7px;
}

.defatul-cost-tbox{
    height: 25px;
    text-align: right;
}
.pr-8{
    padding-right: 8px;
}
.copy_to_model_table .ReactTable{
    height: calc(100vh - 380px) !important;
}
.copy_to_local .ant-modal-footer {
    padding: 10px 25px;
}
.copy_label {
    font-size: 12px;
    color: #0477bd;
}
.copy_to_local_btn {
    padding: 0px 30px !important;
}
.copy_to_local_btn i {
    position: absolute;
    right: 5px;
    top: 9px;
}
.copy_to_local_model .ant-steps-item:first-child > .ant-steps-item-container .ant-steps-item-title {
    text-align:left;
    width: 100%;
}
.copy_to_local_model .ant-steps-item:last-child > .ant-steps-item-container .ant-steps-item-title {
    text-align:right;
    width: 100%;
}

.linked_data_count{
    width: 100%;
    height: auto;
}
.linked_data_count a{
    display: inline-block;
    width: 100% !important;
    height: auto;
}

.virtual_scroll_bar{
    /* height: calc(100vh - 324px) !important; */
    position: absolute;background: #ccc0;
width: 16px;
overflow: scroll;
z-index: 99;
top: 37px;
float: right;
right: -1px;
}
.virtual_scroll_bar div{
    width: 2px;
}

.ant-dropdown-menu .anticon{margin-right: 7px; }
  .ant-collapse > .ant-collapse-item {border-bottom: 1px solid #d9d9d9;}
  .ant-collapse {
    color: rgba(0, 0, 0, 0.65);
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-bottom-color: rgb(217, 217, 217);
    border-bottom-style: solid;
  }
  .ant-collapse-header{border-bottom: 1px solid #d9d9d9;}
  .ant-radio-group { font-size: 14px !important;}

  .message_box .ant-modal-body{ min-height: 100px !important;}
  .row { margin-left: 0px; margin-right: 0px;}