a.icon > svg {
    width: 20px !important;
    height: 20px !important;
}
.width-25 {
    width: 25px;
}
.width-35 {
    width: 35px;
}
a.pointer {
    cursor: default;
}

.user_profile img {
    border-radius: 50%;
    width: 35px;
    float: left;
    height: 35px;
    object-fit: cover;
    margin-top: 3px;
}
.badge_container {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 6px 10px;
    border-radius: 4px !important;
}
.navbar-brand {
    padding-top: 16px !important;
    padding-right: 0px;
}
.navbar-brand svg {
    margin-top: 14px;
    margin-right: 25px;
}
.navbar-co-brand {
    display: flex;
    height: 66px;
    align-items: center;
}

.navbar-co-brand .logo-nav {
    max-width: 200px;
    width: auto;
    max-height: 66px;
    margin-left: 5px;
}

.navbar {
    margin: 0px !important;
    border: 0 !important;
    height: 66px;
    border-radius: 0px !important;
    position: relative;
    z-index: 200;
}

.horizontal_menu .display_table_cell {
    padding-right: 30px;
}

.horizontal_menu .display_table_cell.user_profile {
    padding-right: 0px;
    max-width:182px;
}
.horizontal_menu .display_table_cell.user_profile span{
    margin-left: 10px;
    display: block;
    max-width: 137px;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    float: left;
    line-height: 40px;
}

.ant-popover-title {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    padding: 8px 16px !important;
    letter-spacing: 1px;
}

.ant-popover-arrow {
    width: 16.071068px !important;
    height: 16.071068px !important;

}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow{
    top:5px !important;
}

.ant-popover-inner-content {        
    padding: 0px !important;
    display: grid;
    width: 100%;
    max-width:300px;
}
.ant-popover-inner-content p {
    margin-top: 8px;
    margin-bottom: 8px;
}
.see_all {
    font-weight: 600;
    font-size: 12px;
}
.ant-popover-inner-content .pop_p a {
    font-size: 12px;
    word-wrap: break-word;
}
.ant-popover-inner-content .pop_p {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    width: 100%;
    display: inline-table;
}
.ant-popover-inner-content p {
    margin-top:0px !important;
    max-width: 300px;
}
.ant-popover-inner-content #content-end {
    margin-top: 8px;
}
.ant-popover-inner-content .pop_p span {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-right: 10px;
    position: relative;
    top: -3px;
    border-radius: 50%;
}

.lang {
    height: 25px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    line-height: 27px;
    font-size: 12px;
    float:left;
    width: 100%;
}

.lang span {
    float: left;
}

.lang img {
    width: 26px;
    float: left;
    margin-right: 10px;
}
.profile_menu>li>a {
    display: block;
    padding: 5px 15px !important;
    clear: both;
    line-height: 1.42857143;
    white-space: nowrap;
    font-size: 12px !important;
}
.profile_menu>li>a>svg{
    font-size: 12px !important;
    margin-right: 10px;
}

.profile_menu>li>a>i {
    font-size: 12px !important;
    margin-right: 10px;
}

.profile_menu img {
    width: 20px;
    margin-right: 10px;
}

.ant-popover {
    min-width: 280px;
}

p.pop_p {
    padding: 7px 16px;
    cursor: pointer;
}
.dot_div {
    float: left;
    display: inline-block;
    width: 8%;
}

.noti_msg {
    float: left;
    width: 92%;
    font-size: 12px;
    margin-bottom: 5px;
    word-break: break-word;
    word-wrap: break-word;
}

.d-block {
    display:block;
}

.w_131 {
    width: 110px;
}
.w_language {
    max-width: 131px;
}

.settings_popup .modal-header h4 svg {
    margin-right:10px;
    font-size: 15px;
}

.settings_popup .modal-header .close {
    margin-top: 3px !important;
}
.top_66 {
    top: 66px !important;
}
.administrator_a a {
    cursor: pointer;
}
.administrator_a svg {
    vertical-align: text-top;
}
.cool-link {
    display: inline-block;
    text-decoration: none;
}
  
.cool-link::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    transition: width .3s;
}
  
.administrator_a:hover .cool-link::after {
    width: 100%;
}
  
@media only screen and (max-width:767px) {
    .navbar-co-brand .logo-nav {
        max-width: 150px;
        width: auto;
        max-height: 66px;
        margin-left: 5px;
    }
    .navbar-brand {
        padding-top: 8px !important;
        padding: 0px;
    }
    .horizontal_menu .display_table_cell {
        padding-right: 20px;
    }
    .horizontal_menu .display_table_cell.user_profile span {
        max-width: 80px;
    }
    .dropdown-menu {
        min-width: 130px;
    }
    .mobile_icons .dropdown-menu1 {
        left:-104px;
    }
    .mobile_icons .dropdown-menu2 {
        left:-94px;
    }
    .w_131 {
        width: 100px;
    }
    .profile_menu>li:first-child {
        background-color: #e6e6e6 !important;
    }
    .profile_menu>li:first-child a {
        cursor:default !important;
        background-color: #e6e6e6 !important;
    }
    .badge_container {
        position: absolute;
        top: 74px;
        right: 15px;
        padding: 6px 10px;
        border-radius: 4px !important;
    }
}

@media only screen and (min-width:768px) {
    .navbar-brand .logo-nav {
        position: relative;
        top: -6px;
        width: auto;
        height: 44px;
        cursor: pointer;
    }
}

.warning_icon svg {
    height: 25px;
    width: auto;
}
.warning_icon svg path {
    fill: #fda505;
}
.h_165px {
    height: 165px;
}
.warning_content {
    overflow: auto;
}
.warning_content a {
    color: #000000;
}
.warning_content div:hover {
    cursor: pointer;
    background-color: #f5f5f5;
}

.warning_content::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    display: block;
}
/* Track */
.warning_content::-webkit-scrollbar-track {
    border-radius: 10px;
}
/* Handle */
.warning_content::-webkit-scrollbar-thumb {

    border-radius: 10px;
}
.width-100{
    width: 100%;
}


/* mom_administrator */

    .mom_administrator {
        padding-left: 30px;
    }
    .mom_administrator .dropdown-toggle {
        border: 1px solid #4B78B2;
        border-radius: 6px;
        background-color: transparent;
        outline: none;
        box-shadow: 0px 3px 6px 0px #00000029;
        padding: 5px 8px;
    }
    .mom_administrator.open .dropdown-toggle {
        background-color: #4B78B2;
    }
    .mom_administrator .dropdown-toggle > i:nth-of-type(1) {
        font-size: 13px;
    }
    .mom_administrator .dropdown-toggle > i:nth-of-type(2) {
        font-size: 12px;
    }
    .mom_administrator .dropdown-toggle > svg , .mom_administrator .dropdown-toggle > i {
        color: #4B78B2;
    }
    .mom_administrator.open .dropdown-toggle > svg , .mom_administrator.open .dropdown-toggle > i {
        color: #ffffff;
    }
    .mom_administrator .dropdown-menu {
        width: 450px;
        left: -167px;
        margin-top: 0;
        top: 56px;
        padding: 4px 4px;
        border-radius: 4px;
        box-shadow: 0px 3px 6px 0px #04476045;
    }
    .mom_administrator .dropdown-menu .drop_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .mom_administrator .dropdown-menu .drop_top > .drop_top_search {
        border: 1px solid #DCDFE1;
        border-radius: 4px;
        padding: 3px 8px;
        display: flex;
        align-items: center;
        width: calc(100% - 65px);
    }
    .mom_administrator .dropdown-menu .drop_top .mom_drop_logo {
        width: 50px;
        text-align: center;
    } 
    .mom_administrator .dropdown-menu .drop_top > .drop_top_search > svg {
        font-size: 16px;
        color: #585C69;
        margin-right: 8px;
    }
    .mom_administrator .dropdown-menu .drop_top > .drop_top_search > input {
        border: none;
        outline: none;
        box-shadow: none;
        border-left: 1px solid #DCDFE1;
        padding: 3px 3px 3px 3px;
        width: calc(100% - 24px);
    }
    .mom_administrator .dropdown-menu .drop_content {
        padding: 10px 0px;
        height: 200px;
        overflow: auto;
    }
    .mom_administrator .dropdown-menu .drop_no_item {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .mom_administrator .dropdown-menu .drop_item{
        padding: 7px;
        margin-bottom: 4px;
        color: #6B7175;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom: 1px solid #DEE9EF;
        border-radius: 0;
    }
    .mom_administrator .dropdown-menu .drop_item a{
        margin-bottom: 4px;
        color: #6B7175;
        white-space: break-spaces;
        
    }
    .mom_administrator .dropdown-menu .drop_item:hover, .mom_administrator .dropdown-menu .drop_item:hover > a{
        background-color: #81B4E1;
        color: white;
        border-radius: 4px;
        border-bottom: 1px solid transparent;
        transition: background-color .3s;
        cursor: pointer;
    }
    @media only screen and (max-width:767px) {
        .mom_administrator {
            padding-left: 20px;
        }
    }

    .position-relative{
        position: relative;
    }

    .search-loader{
        position: absolute;
        left: 40%;
        top: 40%;
        font-size: 42px;
        color: #81B4E1;
    }