.administration_headings {
    width: 100%;
    display: inline-block;
    padding: 10px;
    text-decoration: none;
    font-size: 16px;
    text-align: center;
    cursor:default;
}

.admin_panel .first_a.selected {
    font-family: 'Proxima Nova Bold', sans-serif;
}