/* left image styles */
.left_image_container {
    background-image: url(/public/images/mom_bg.jpg);
    background-position-x: 75%;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}

.left_image_container .bottom_text {
    position: absolute;
    left: 45px;
    bottom: 45px;
}

.left_image_container .bottom_text .first_line {
    font-size: 35px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.left_image_container .bottom_text .second_line span {
    font-size: 22px;
    letter-spacing: 1.5px;
    margin-right: 5px;
    text-transform: uppercase;
}

/* left image styles ends */