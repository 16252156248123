.label_info{
    left: 0;
    color: #da7c1a !important;
    float: right;
}
.height_50px{
    height: 50px;
}
.float_right{
    float:right !important;
}
.info_tooltip {
    columns: #ffffff;
}
.info_tooltip .anticon-info-circle{
    position: initial;
}
.info_tooltip .anticon-info-circle svg{
    color: #da7c1a;
    width: 18px;
    height: 18px;
}
.info_tooltip .ant-tooltip-body  h5{
    color: #da7c1a;
}