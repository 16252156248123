.summary-block{
    /* margin-top: 3px; */
}
.summary-block .header{
    padding: 10px;
    background-color: #6e7d8a;
    color: white !important;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 9;
    border-top: 2px solid white;
}
.summary-block .ant-collapse {
    border: none !important;
    font-size: 12px;
}
.summary-block .ant-collapse  .ant-collapse-item {
    border: 1px solid #d9d9d9;  margin-top: 5px; }
.summary-block .ant-collapse  .ant-collapse-item  .ant-collapse-header {
    padding: 0px 10px !important;
    font-size: 12px;
}
.summary-block h5 {color:#023244 !important; font-size: 12px;}
.summary-block .ant-collapse  .ant-collapse-item  p{ padding: 10px; color:#023244 !important;}
.summary-block .ant-collapse-expand-icon { position: relative; right: unset;}
.summary-block .ant-collapse>.ant-collapse-item:last-child {  border: 1px solid #d9d9d9;  margin-top: 5px; padding: 0px; color:#023244 !important;}
/* .summary-block .last-pannel  .ant-collapse-header{ padding: 6px 16px !important;} */
/* .summary-block .ant-collapse>.ant-collapse-item:first-child, .summary-block .ant-collapse>.ant-collapse-item:first-child  p { background-color: #dbf6fd;}
.summary-block .ant-collapse>.ant-collapse-item:nth-child(2), .summary-block .ant-collapse>.ant-collapse-item:nth-child(2)  p { background-color: #e9e7fd;}
.summary-block .ant-collapse>.ant-collapse-item:nth-child(3), .summary-block .ant-collapse>.ant-collapse-item:nth-child(3)  p { background-color: #fee4cb;}
.summary-block .ant-collapse>.ant-collapse-item:nth-child(4), .summary-block .ant-collapse>.ant-collapse-item:nth-child(4)  p { background-color: #ffd3e2;} */
.summary-block .bullet-list li{ list-style: disc;}
.ifram-preivew {border-radius: 10px; border: none;margin-top: 10px;
    -webkit-box-shadow: -2px 1px 20px 8px rgba(0,0,0,0.19);
    -moz-box-shadow: -2px 1px 20px 8px rgba(0,0,0,0.19);
    box-shadow: -2px 1px 20px 8px rgba(0,0,0,0.19);
}
.preview_diagram {
  width: calc(100vw - 20px) !important;
}

.preview_diagram .ant-modal-body {
    height: calc(100vh - 75px);
    overflow: scroll;
}
.drop-down-list {
    max-height: 250px;
    overflow: scroll;
}