.table_sub_head {
    background-color: #72B2DF
 !important;
    padding: 2px 0px !important;
    font-size: 12px;
}
.table_sub_div tbody td {
    font-family: 'Proxima Nova';
    font-weight: normal;
    font-size: 12px;
    text-align: left;
}
.table_sub_div thead th {
    font-size: 12px;
}.edit_button_clp {
    float: right;
    font-family: 'Proxima Nova';
    font-weight: bolder;
    font-size: 10px;
    text-align: center;
    background-color: transparent;
    border-color: white;
    text-decoration: underline;
    padding-top: 0px;
}.circle {
    align-items: center;
    width: 20px;
    height: 20px;
    background:blue;
    border-radius: 50%}
.hospitalname_tag {
    width: 10px;
    height: 10px;
    border-radius: 50%; 
    background-color: green;
}
.hospitalname_tag.hospitalnameactive {
    background-color: blue;
}
.editor_no_edit .DraftEditor-editorContainer > div:nth-of-type(1)  {
    min-height: auto;
}
.table_no_data_edit .rt-noData {
    padding: 0px !important;
}
.check_box_container_table_main_head {
    padding: 0px 10px;
    width: 78px;
    text-align: center;
}
.check_box_container_table_sub_head {
    padding: 0px 10px;
    width: 48px;
    text-align: center;
}
.check_box_container_table_sub_head_modal {
    padding: 0px 7px;
    text-align: center;
}
.clinical_pathway_container {
    height: calc(100% - 40px) !important;
}
.clinical_pathway_main {
    overflow: hidden !important;
}
.main_overview_section .ant-collapse-container .ant-collapse-content-box {
    padding: 0px;
}
.main_overview_section .ant-collapse-container .ant-table-thead tr th {
    padding: 6px;
}
.main_overview_section .ant-collapse-container .ant-table-tbody tr td{
    padding:6px;
}
.main_overview_section .ant-collapse-container .ant-table-tbody tr td span{
       font-weight: 700;
}
.main_overview_section .pathway_searchbox input {
    width: 100% !important;
}
.item_level1 {
    font-size: 20px;
    color: #286090

;
    font-weight: 700;
}
.item_level2 {
    font-size: 18px;
    color: #286090

;
    font-weight: 700;
    font-style: italic;
}
.item_level3 {
    font-size: 16px;
    color: #286090

 !important;
    font-style: italic;
    font-weight: 700!important;
}
.create_modal_popup label {
    margin-bottom: 0px;
}
.create_modal_popup .create_level .ant-select-selection{
    height: 34px;
}
.create_modal_popup .invalid_right {
    top:3px !important;
}
.btn_container_absolute {
    display: flex;
    position: absolute;
    z-index: 1;
    margin-top: -37px;
    right: 28px;    
}
.ant-sub-steps {
    display: flex;
    flex-wrap: wrap;
    width: 600px;
}
.sub-step-bold-text {
    font-weight: bold;
}
.pathway_problem_btn_input {
    width: 100%;
    margin-left: 15px;
    margin-right: 14px;
}
.tool_tip_pathway .ant-tooltip-content .ant-tooltip-inner,  .tool_tip_pathway .ant-tooltip-arrow::before {
    background-color: #FFFFFF
 !important;
    border: 2px solid #CCCCCC
 !important;
    color: #555555
 !important;
}.pathway_problem_textarea {
    width: calc(100% - 445px);
}
.pathway_problem_textarea label { 
    word-break: break-all !important;
}
.form_control_update input, .disabled_textarea{
    background-color: #FFFFFF
 !important;
    border: 1px solid #CCCCCC
 !important;
}@media only screen and (max-width: 1300px) {
    .pathway_problem_textarea label {
        font-size: 11px;
    }
    .pathway_problem_btn .radio_grp_container span {
        font-size: 11px;
    }
  }
.steps_wrapper .ant-steps-item-title {
    width: auto;
    text-align: left;
}