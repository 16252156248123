.sub-header td{
    text-align: center;
    border: 1px solid #dddddd;
    background-color: rgb(233, 231, 231);
}
.reorder-table{ width: 100%;}
.reorder-table .ant-table-wrapper{margin-top: -1px;}
.drop-list-nav {
    position: absolute;
    width: 500px;
    right: 66px;
    display: flex;
    top: 2px;
}
.drop-list-nav .disabled svg{ color: rgb(136, 143, 150);}
.drop-list-nav li:nth-child(1){
    width: 30px;
    height: 38px;
    position: relative;
    cursor: pointer;
}
.drop-list-nav li:nth-child(2) {
    width: 425px;
    position: relative;
}

.drop-list-nav li:nth-child(3) {
    width: 30px;
    height: 38px;
    position: relative;
    right: 7px;
    cursor: pointer;
}

.drop-list-nav li svg { font-size: 36px;}
.drop-list-nav .css-1nmdiq5-menu { color: black;}
.drop-list-nav .css-1fdsijx-ValueContainer { color: black;}
.drop-list-nav .css-8j2bc4-singleValue { color: black;}

