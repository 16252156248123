

/* right login container styles */

.right_login_conatiner {
    height: 100%;
    max-height: 100%;
    overflow-y: scroll;
}
.right_login_conatiner .dropdown{
    position: absolute;
    top: 30px;
    right: 60px;
}
.right_login_conatiner .dropdown-menu {
    min-width: 100px !important;
}
.right_login_conatiner .dropdown-toggle {
    border-radius: 4px;
    padding: 0px;
    padding: 2px 8px;
    height: auto;
    opacity: 0.7;
}
.right_login_conatiner .dropdown-toggle:hover {
    opacity: 1;
}

.login_header_section img {
    width: 200px;
}
.login_header_section .text_bottom {
    font-size: 15px;
}

.login_input_container .input_group {
    position: relative;
}   
.login_input_container .input_group img {
    width: 16px;
    position: absolute;
    top: 11px;
    left: 10px;

}
.top_11px {
    top: 11px !important;
}
.width_15 {
    width: 13px !important;
    top: 6px !important;
    left: 11px !important;
}
.login_input_container .input_group input {
    width: 100%;
    height: 34px;
    padding: 5px 10px 5px 40px;
    border:none;
    outline: none;
}
.forgot_password a {
    font-size: 12px; 
}
.login_button button {
    border-radius: 5px;
    font-size: 12px;
    padding: 9px 35px;
    min-width: 100%;
    outline: none;
}

.bottom_buttons {
    padding: 8px 15px;
    font-size: 12px;
    margin-bottom: 10px;
    border-radius: 4px;
}

.w_131 {
    max-width: 131px;
}

.display_table_cell {
	display: table-cell;
	vertical-align: middle;
}

.login_lanaguage_box {
	position: absolute;
    right: 7%;
	top: 20px;
	padding: 2px 5px;
    border-radius: 5px;
}

.login_lanaguage_box .dropdown-menu {
    min-width: 130px;
}

.reset_password_eye {
    position: relative;
}
.reset_password_eye input {
    padding-right: 36px;
}
.reset_password_eye a {
    position: absolute;
    right: 11px;
    top: 7px;
}

@media screen and (min-width: 767px) and (max-width: 991px) {
	.login_lanaguage_box {
		position: absolute;
		right: 11%;
		top: 20px;
	}
}

@media screen and (max-width: 767px) {
    .login_lanaguage_box {
		position: relative;
		float: right;
		margin: 20px 74px;
		right: 0;
		top: 0;
    }
    .login_header_section {
        margin-top: 100px;
    }
}

.lang {
    height: 25px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    line-height: 27px;
    font-size: 12px;
    float:left;
    width: 100%;
}

.lang span {
    float: left;
}

.lang img {
    width: 26px;
    float: left;
    margin-right: 10px;
}

.profile_menu>li>a {
    display: block;
    padding: 5px 15px !important;
    clear: both;
    line-height: 1.42857143;
    white-space: nowrap;
    font-size: 12px !important;
}
.profile_menu>li>a>svg{
    font-size: 12px !important;
    margin-right: 10px;
}

.profile_menu>li>a>i {
    font-size: 12px !important;
    margin-right: 10px;
}

.profile_menu img {
    width: 20px;
    margin-right: 10px;
}

.no-padding {
	padding: 0 !important;
}