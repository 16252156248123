.edit-form {
    height: 100%;
}
.profile_outer_container {
    padding: 70px 0px;
}

.profile_img img{
    margin:auto;
    border-radius: 50%;
    height: 120px;
    width: 120px;
    object-fit: contain;
}

.profile_image_div {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    margin: auto;
    cursor: pointer;
}

.profile_image_div img:hover {
    opacity: 0.8;
}

.company_image_div {
    height: 100px;
    width: 100px;
    margin: auto;
    cursor: pointer;
}
.company_image_div img{
    margin:auto;
    height: 100px;
    width: 100px;
    object-fit: contain;
}
.company_image_div img:hover {
    opacity: 0.6;
}


.special_select .ant-select-selection-selected-value {
    padding-bottom: 0px !important;
}

.group textarea {
    width: 100%;
    resize: none;
    padding: 5px;
}
.di {
    display: inline-block;
}
.messenger_form_popup .group input {
    font-family: 'Proxima Nova', sans-serif;
}
.top_10px {
    left: 0px;
    margin-bottom: 0px;
    display: inline-block;
    position: relative !important;
    left: 0px !important;
}

.ellipsis_input .group input {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.profile_label {
    left: 5px !important;
    font-size: 10px !important;
}
.border_radius20 {
    border-radius: 20px !important;
    font-size: 12px !important;
}
.profile_input_eye {
    position: relative;
}
.profile_input_eye input {
    padding-right: 36px;
}
.profile_input_eye a {
    position: absolute;
    right: 11px;
    top: 6px;
}
.bg_tablets {
    background-image: url("/public/images/profile_bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.apply_div button {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 9px !important;
    font-size: 13px !important;
}
.bg-fff {
    background-color: #fff;
}
.box-shadow {
    box-shadow: 2px 9px 21px 3px #eaeaea;
}
.profile_img {
    margin:90px 0px;
}
.form_container {
    padding: 50px;
}
@media only screen and (max-width:767px) {
    .save_reset {
        margin-top: 30px;
    }
    .reset_profile {
        float: left;
    }
    .profile_img {
        margin:30px 0px;
    }
    .form_container {
        padding: 15px;
    }
    .profile_outer_container {
        padding: 30px 0px;
    }
    .edit-form {
        height: auto;
    }
}
