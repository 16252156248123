.special_header_select {
    padding-top: 25px;
    margin-top: 5px;
}
.special_header_button {
    padding-top: 25px;
    margin-top: 5px;
}
.risk_preparation_header {
    padding: 15px 0px 15px 15px;
}
.margin_top_18_action_button {
    margin-top: 26px;
}
.margin_top_26mob{
    margin-top: 26px;
}
.total_price_margin {
    margin-left: auto !important;
    margin-right: 0 !important;
}
.medical_device_box {
    padding: 15px;
    margin-bottom: 15px;
    padding-bottom: 0px;
    margin-top:-1px;
    background-color: #cccccc21;
}
.css-1pcexqc-container .css-dgoi55-control, .css-azozfy-control {
    padding: 3px;
}
.ant-select {
    background-color: white;
}
.common_button_color {
    color: white !important;
}
@media (max-width:767px){
    .margin_top_15px_mob {
        margin-top: 15px;
    }
    .special_header_select {
        padding-top: 25px;
        margin-top: 5px;
    }
    .special_header_button {
        padding-top: 10px;
        margin-top: 5px;
    }
    .margin_top_18_action_button {
        margin-top: 15px;
    }
    .margin_top_26mob{
        margin-top: 0px;
    }
    .risk_preparation_header {
        padding: 15px;
    }
}

.bottom_images img {
    max-height: 250px;
    max-width: 250px;
}

.position_del_btn_wrap {
    position: absolute;
    top: 5px;
    right: 5px;
}

.w_90 {
    width: 90%;
    float: left;
}

.w_10_text {
    width: 10%;
    float: left;
    margin-top: 5px;
    text-align: left;
}

.invalid_custom_1 {
    top: 36px !important;
    left: calc(10% + 15px) !important;
    text-align: left;
}
.add_image_text {
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
    position: absolute;
    left: 0;
    z-index: 2;
    cursor: pointer;
}

.switch_templater_container {
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 20px;
    margin-top: 15px;
    position: relative;
}
.switch_templater_label {
    position: absolute;
    padding: 3px 10px;
    z-index: 10;
    background-color: #ffffff;
    top: -12px;
    left: 10px;
}
body .switch_template_cancel_btn {
    width: 100px;
    padding: 6px 15px !important;
    border-radius: 4px;
}
.invalid_switch {
    position: absolute;
    right: 15px;
    top: -18px;
    color: rgb(233, 87, 87);
}
.risk_preparation_header .invalid_intervention_edit {
    position: absolute;
    right: 13px;
    top: 3px;
    font-size: 12px;
}
