.w_200p {
    width: 300px;
    padding: 4px 4px;
}

.float_right_li_special {
    float: right !important;
}

.margin-right-10{
    margin-right: 10px;
}
.waiting-for-approve{
   float: left;
   width: 24px;
}
.waiting-for-approve img{
    width: 10px;
}

.bold{
    font-weight: bold;
}

.pl-10{
    padding-left: 10px;
}


.pt-10{
    padding-top: 10px;
}
.pr-0{
    padding-right: 0 !important;
}
.width-100{
    width: 100%;
}
.pl-15{
    padding-left: 15px;
}
.pl-20{
    padding-left: 20px;
}
.re_assign_modal_head{
    /* margin-left: -24px !important;
    margin-right: -24px !important;
    margin-top: -24px !important;
    background: #eaeaea;
    padding-top: 10px;
    position: relative;
    width: calc(100% + 48px); */
    background: #eaeaea;
    padding-top: 10px;
    position: relative;
    width: calc(100% + -1px);
}

.hospital_name{
    position: absolute;
    right: 25px;
    color: #1e03fd;
}

@media (min-width:768px) and (max-width: 991px){
    .w_200p {
        width: 200px;
        padding: 4px 4px;
    }
}
@media (max-width: 767px){
    .w_200p {
        width: 100%;
        padding: 0;
    }
    .float_none_mob {
        float: none !important;
    }
    .float_right_li_special {
        float: none !important;
    }
    .add_di_w_100 {
        display: inline-block !important;
        width: 100%;
    }
}

.invalid_right_popup {
    position: absolute;
    right: 5px;
    font-size: 12px;
    bottom: 0;
}