.side_bar_section {
    width: 20%;
    float: left;
    height: 100%;
    overflow-y: scroll;
    position: relative;
    z-index: 100;
}

.side_bar_section .side_bar_items {
    width: 100%;
    display: inline-block;
    padding: 10px 20px;
    text-decoration: none;
}

.side_bar_item_inner_section .second_a {
    width: 100%;
    display: inline-block;
    padding: 10px 15px 10px 20%;
    text-decoration: none;
}

@media (max-width:991px){
    .side_bar_section {
        position: absolute;
        width: 260px;
        top: 66px;
        bottom: 0;
        height: calc(100% - 66px);
    }
    .main_overview_section {
        width: 100% !important;
    }
}