.intervention_delete_modal_left-align {
	float: left;
  }
  
  .intervention_delete_modal_right-align {
	float: right;
  }

  .re_assign_modal .ant-modal-footer {
    display: inline-block;
    width: 100%;
}
.Intervention_deletion_modal {
	width: 100% !important;
	max-width: 750px;
}