.h-100 {
    height: 100%;
}

.clp_step_filter.parcours_filter {
    margin-bottom: 0 !important;
    border-color: #d6d1d1;
}

.clp_step_filter.parcours_filter .btn{
    color: #000000a6;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
    font-size: 12px;
}
.clp_step_filter.parcours_filter.parcours_filter_clinical_pathway {
    /* margin-bottom: 10px !important;  */
    margin-bottom: 0px !important;
    height: 32px;
}