.text_area_treatment {
    height: 114px !important;
    resize: none;
}
.scaned_label_header {
    color: #3E3E3E;
    font-size: 17px;
  
}
.model_content_color {
    color: #3c3c3c;
}
.scaned_primary_color_head {
    border: 1px solid #dddddd;
    padding: 10px 15px;
    border-radius: 5px;
    margin: 5px 0px;
}
.scaned_primary_header {
    font-size: 17px;
}
.romove_link_btn_scan {
    text-align: right;
    padding-top: 35px;
}
.other_sugections {
    padding-top: 10px;
    color: #070707;
    font-size: 15px;
}
.scanned_search_model input {
    display: block;
    width: 100%;
    height: 32px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
}
.button_div {
    display: inline-block;
    vertical-align: top;
    width: 120px;
    padding: 0px 10px;
}
.button_div_else {
    display: inline-block;
    vertical-align: top;
    width:calc(100vh - 120px);
    padding: 0px 10px;
}
.mt-25{
    margin-top: 25px;
}
.ml-15{
    margin-left: 15px;
}
.d_flex {
    display: flex;
}
.align_items_base {
    align-items: flex-end;
}
.z_index_10_select > div:nth-of-type(1) {
    z-index: 10;
}
.invalid_day_details {
    position: absolute;
    top: 57px;
    right: 0;
    font-size: 11px;
    color:#d07492 !important;
}
.stay {
    position: absolute;
    font-weight: bold;
    top: -14px;
    right: -10px;
    font-size: 11px;
    min-width: 87px;
}
.margin_row {
    margin: 0px -15px;
}
.show_hide_new_popup .ant-popover-content {
    padding: 15px !important;
    border: 5px solid #ddd;
    border-radius: 15px;
    background-color: white;
}
.show_hide_new_popup .ant-popover-inner {
    background-color: transparent;
    box-shadow: none;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
}
.show_hide_new_popup_box_shadow_none.show_hide_new_popup .ant-popover-inner {
    box-shadow: none;
}
.show_hide_new_pop button {
    background-color: #41FDFF !important;
    color: black !important;
    border-radius: 15px;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}
.search_type {
    display: flex;
    justify-content: center;
}
.length_of_stay {
    width: 80px;
    position: relative;
    margin-left:15px;
}
.hour_input {
    max-width: 52px;
}
.day_of_procedure {
    width: 155px;
}

.ant-modal-body .day_info .rt-table{
    height: 350px;
}
/* .ant-modal-body .day_info .rt-table .rt-body{
    height: 350px !important;
    overflow: scroll !important;
} */
.text_decoration_underline {
    text-decoration: underline;
}
.text_decoration_underline:hover {
    text-decoration: underline !important;
    opacity: .9;
}

.title_line{
    text-align: left; 
    border-bottom: 1px solid #c1bfbf; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
 } 
 
 .title_line:after {
    content:"";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.5em;
    border-top: 1px solid black;
    z-index: -1;
}