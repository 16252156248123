.create_category_personal_width_100 {
    width: 100% !important;
}
.common_label {
    font-size: 12px;
    margin-bottom: 8px;
}
.min_width_300 {
    min-width: 300px;
}
.margin-top-neg-3 {
    margin-top: -3px;
}