.steps-content {
    margin-top: 20px;
    border-radius: 6px;
  }

  .steps-content .css-10nd86i > div:first-child {
    height: auto !important;
    cursor: pointer !important;
    min-height: auto !important;
  }

  .steps-content .css-10nd86i > div:nth-child(2) {
    cursor: pointer !important;
    height: auto !important;
    min-height: auto !important;
  }

  .steps_outer_container_mom {
    border-radius: 6px;
  }
  .ant-steps-dot .ant-steps-item-title {
    line-height: 1.5;
    font-size: 14px;
  }
  .steps-action {
    margin-top: 24px;
  }
  .steps-action button {
    border: none;
    border-radius: 6px;
  }
  .step_header {
    padding: 10px;
    font-size: 16px;
    margin: 0px 10px;
  }
  .step4_second_header {
    font-size: 15px;
  }
  .steps_contents {
    display: inline-block;
    width: 100%;
    padding: 20px;
  }
  .steps_contents .invalid_day_steps_three{
    right:0;
    left:15px;
  }
  .step4_second__contents {
    display: inline-block;
    width: 100%;
    padding: 20px;
  }
  .steps_label {
    margin-top: 8px;
    display: inline-block;
    font-size: 12px;
  }
  .steps_label_special {
    margin-top: 8px;
    display: inline-block;
    font-size: 12px;
    padding-top:25px;
  }
  .special_character_span {
    font-size: 11px;
    float: right;
    display: inline-block;
    margin-top: 7px;
  }
  .code_header {
    font-size: 15px;
    font-weight: 600;
  }

  .code_value {
    font-size: 14px;
  }

  .step_two_container {
    height: 300px;
  }

  .radio_container {
    margin:auto;
    width: 45%;
  }
  .radio_check_item {
    font-size: 15px;
    font-weight: 600;
  }
  .padding-top-25-steps {
    padding-top:25px;
  }
  .padding-top-10-steps {
    padding-top:10px;
  }
  .margin-bottom-10-steps {
    margin-bottom: 10px;
  }
  .top_neg_10 {
    top: -10px !important;
  }
  .spacer-top-10 {
    margin-top:10px !important;
  }
  @media (max-width:991px){
    .steps_label {
      margin-bottom: 8px;
      margin-top: 0px;
    }
  }
  @media (max-width:767px){
    .steps_label_special {
      padding-top:0px;
      margin-bottom: 8px;
      margin-top: 0px;
    }
    .padding-top-25-steps {
      padding-top:0px;
    }
    .padding-top-25-steps .invalid {
      top: -25px !important;
    }
    .margin-bottom-10-steps {
      margin-bottom: 20px;
    }
    .radio_container span {
      white-space: normal;
      word-break: break-word;
      word-wrap: break-word;
      font-size: 12px;
    }
    .radio_container {
      margin: auto;
      width: 100%;
      padding: 20px;
    }
  }

  .special_character_tooltip .ant-tooltip-inner{
    min-width: 250px;
    word-wrap: break-word !important;
  }
  .name_field .anticon-info-circle {
    position: absolute;
    right: 10px;
    top: 34px;
    cursor: pointer;
    font-size: 16px;
  }
  @media (max-width:767px) {
    .name_field .anticon-info-circle {
      position: absolute;
      right: 12px;
      top: 10px;
    }
  }
  .invalid_day_create{
    position: absolute;
    top: -20px;
    right: 0;
    font-size: 11px;
    color:#d07492 !important;
  }