.f-bold {
    font-weight: bold;
}

.f-14 {
    font-size: 14px;
}

.f-12 {
    font-size: 12px;
}

.f-11 {
    font-size: 11px;
}

.padL-10 {
    padding-left: 10px;
}

.padL-16 {
    padding-left: 16px;
}

div.record_key_value_div>p.record_key_name {
    margin-bottom: 4px;
}