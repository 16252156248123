.durationTypeSet label{
    margin: 0px;
    
}
.durationTypeSet .ant-radio-button-wrapper-checked {
    background-color: #323ada !important;
    border-color: #323ada !important;
    --antd-wave-shadow-color: #323ada !important;
}
.margin-alignment-min{
    margin-left: -19px;
}
.duration-select-alihnment{
    margin-left: -40px;
}
.duration-inputs-style{
    margin-right: 14px;
    height: 36px;
    margin-left: 176px;
    margin-top: -46px;
}
.invalid-duration {
    position: absolute;
    top: -16px;
    color: red;
    right: 0px;
}
.duration-container {
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 5px;
    display: flex;
    align-items: center;
}
.duration-content-container {
    flex: 1 1 10px;
    width: 10px;
    text-align: center;
}