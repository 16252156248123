.collaps_header {
    box-sizing: border-box;
    position: relative;
    padding: 10px 16px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    background-color: #f5f5f5
    }
  .ant-collapse-header{
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .ant-collapse-content-box{
    padding-left: 40px !important;
    padding-top: 0px !important;
    margin-top: -1px;
  }
.collapse_table_header{
  width: 100%;
  border: 0px;
  padding: 0px;
}
.collapse_table_header th {
  border: 0px;
  padding: 0px;
  text-align: center;
  vertical-align: middle;
}
.collapse_table_header th:first-child{
  width: 300px;
  text-align: left;
}

.collapse_table_body{
  width: 100%;
  border: 0px;
  padding: 0px;
}
.collapse_table_body td {
  border: 0px;
  padding: 5px 0px;
  text-align: center;
  vertical-align: middle;
}
.collapse_table_body td:first-child{
  width: 300px;
  text-align: left;
}

.font-size-12{
  font-size: 12px !important;
}

.permission_master_checkBox{
  font-weight: bold;
  color: #1c1c1c;
}
.permission_master_checkBox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2b8013;
  border-color: #2b8013;
}
.no_data_found{
  text-align: center;
  color:#1c1c1c;
  font-size: 12px;
  padding: 10px;
  margin-top: 10%;
}
.clean_button{
  margin-top: 8px;
  padding-right: 0px;
  text-align: right;
}
.top_elements{
  width: auto;
  float: right;
  margin-left: 10px;
}
.ant-btn-primary[disabled] {
  color: #d9d9d9 !important;
}