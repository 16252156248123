.rank_div {
    margin-left: auto !important;
    width: 100%;
    text-align: center;
    display: inline-flex;
}
.rank_div a {
    width: 20px;
    height: 20px;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
}
.subsidy_bold {
    font-weight: bold;
    font-family: 'Proxima Nova Bold';
}

.rank_div a:hover svg {
    background-color: transparent;
}
.rank_value {
    width: calc(100% - 40px);
    padding: 0px 10px;
}
.exit_element_table_active {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 8px;
}
.color_black {
    color: #000000;;
}
.d-flex {
    display: flex;
} 
.align-items-end {
    align-items: flex-end;
} 
.justify-content-between {
    justify-content: space-between;
}
.align-items-center {
    align-items: center;
}
.justify-content-end {
    justify-content: flex-end;
}
.ant-select-auto-complete.ant-select .ant-input {
    border-width: 0px !important;
    margin-left: 0;
    padding-left: 5px;
}
.ant-select-auto-complete.ant-select .ant-input:focus, .ant-select-auto-complete.ant-select .ant-input:hover {
    border-right: 0px !important;
}
.day_font {
    font-size: 15px;
    font-family: 'Proxima Nova SB';
}
.invalid_day {
    position: absolute;
    right: 13px;
    top: -15px;
    font-size: 12px;
}
.day_common_btn .common_button {
    min-width: inherit;
}
.upload_content_clinic {
    background-color: transparent;
    border: 1px #cecece solid !important;
    color: black;
    width: 100%;
    min-height: 50px;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 4px;
}
.neg-margin { 
    margin: -5px -10px !important; 
    padding: 5px 10px !important;
} 
.color_check {
    margin-bottom: 0px !important;
}
.invalid_right_edit {
    position: absolute;
    right: 0px;
    top: -16px;
    font-size: 12px;
}
.invalid_right_edit_clinical_pathway {
    position: absolute;
    right: 0px;
    top: -39px;
    font-size: 12px;
}
.item_invalid {
    top:17px !important
}
.top_section_invalid {
    top:10px !important
}
.react-resizable {
    position: relative;
    background-clip: padding-box;
} 
.react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    z-index: 1;
}

.full_screen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background: white;
    /* overflow-y: auto; */
}

.toggle_fullscreen_icon_div {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 16px 18px 12px 18px;
    z-index: 999;
    cursor: pointer;
}

.fullscreen_sub_step_container {
    overflow-y: scroll;
}
