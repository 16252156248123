.patient_info_drop_down.btn-group{
    display: inline-flex !important;
    float: left;
}

.patient_info_drop_down .btn-group{
    display: inline-flex !important;
}


.subsidy_bold {
    font-weight: bold;
    font-family: 'Proxima Nova Bold';
  }

.patient_info_drop_down button{
    font-size: 12px;
    border-radius: 4px;
    padding: 4px 13px;
    outline: none !important;
    box-shadow: none!important;
    text-transform: uppercase;
}

.intervention_name {
    font-size: 18px;
    font-family: 'Proxima Nova Bold', sans-serif;
}

.intervention_speciality_name {
    font-size: 15px;
    font-family: 'Proxima Nova Semibold', sans-serif;
    color: black;
    margin-left: 10px;
}

.intervention_speciality_name.font_12 {
    font-size: 12px;
}

.intervention_details .id_total_section {
    padding-right: 60px;
}

.intervention_details .id_total_section span {
    display: inline-block;
}

.intervention_details .id_total_section * {
    font-size: 18px;
}

.intervention_details .rt-th *, .intervention_details .rt-tfoot * {
    font-family: 'Proxima Nova Bold', sans-serif;
}

.mr-20 {
    margin-right: 20px;
}


.common_button.pumpkin {
    font-weight: 700;
    border-radius: 0;
    padding: 7px 60px;
}

.patient_info_drop_down .drop_button{
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}
.patient_info_drop_down .text_button{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-right: 0px !important;
    min-width: 40px;
    cursor: pointer;
}

.patient_info_drop_down button i{
    margin-right: 5px;
    font-size: 12px!important;
}
.patient_info_drop_down button .caret_down{
    margin-right: 0px;
    font-size: 15px!important;
    position: relative;
    top: 2px;
}

.patient_info_drop_down .dropdown-menu{
    padding: 0;
}
.patient_info_drop_down .dropdown-menu a{
    padding: 5px 15px;
    font-size: 12px;
}

.patient_info_drop_down .dropdown-menu a i{
    padding-right: 5px;
    vertical-align: text-top;
}
.patient_info_drop_down .dropdown-menu a svg{
    vertical-align: sub;
    width: 11px;
    font-size: 15px;
}
.patient_info_drop_down .dropdown-menu a img{
    margin-right:10px;
    width: 11px;
    vertical-align: sub;
}
.btn-group button.text_button:hover { 
    cursor: pointer;
}
.btn-group button.text_button.eye {
    padding: 4px !important;
}
.btn-group button.text_button.eye:hover { 
    cursor: pointer;
}
.btn-group button.text_button.eye img {
    width: 13px;
}

.eye_button_section {
    position: sticky;
    top: 0;
    z-index: 99;
    width: calc(100% + 30px);
    margin-left: -15px;
    padding: 0px 15px;
}
.intervention_header .mb-10{
    margin-bottom: 10px;
}

.header_content_box_title{
    padding:5px 10px;
    font-family: 'Proxima Nova Bold', sans-serif;
}
.header_content_box_value{
    padding:5px 10px;
    min-height: 70px;
    word-wrap: break-word;
}
.header_content_box_value ul, .header_content_box_value ol {
    margin-left: 15px;
    margin-top: 16px;
    margin-bottom: 16px;
}
.component_heading {
    font-size: 15px;
    font-weight: 600;
}

.header_copy_paste {
    text-align: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.subcomponent_table .rt-tr .rt-th:first-child { 
    display: none;
}
.subcomponent_table .ReactTable .rt-tr .rt-th:first-child { 
    display: block;
}
.toast_div {
    padding: 15px;
    font-size: 13px;
    position: absolute;
    z-index: 98;
    position: relative;
    margin-left: -15px;
    width: calc(100% + 30px);
    z-index: 98;
}
.toast_div button {
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
}
.special_float_right {
    float: right !important;
}
.cost_modal_check .ant-modal-body {
    position: relative;
}
.cost_modal_check .invalid {
    position: absolute;
    right: 24px;
    top: 25px;
    font-size: 12px;
}
.cost_modal_cost_screen {
    width: 87% !important;
    top: 30px !important;
}
.cost_table_section{
    height: 350px;
}
.cost_table_section .ReactTable {
    height: 350px;
}
.cost_table_section .ReactTable .rt-table{
    height: 100%;
}
.disabled_btn {
    color: #a0a0a0;
    background-color: #ccc;
    pointer-events: none;
}
.prblm_search {
    margin-top:6px;
}
.select_prbl_section .ant-select-selection {
    min-width:155px;
    margin-top:6px;
}
.padding_left_prblm {
    padding-left: 10px;
}
.padding_bottom_20 {
    padding-bottom: 20px !important;
}
.select_task_pathway {
    min-width: 155px;
    margin-top:6px;
}
.pathway_task {
    margin-top: 4px;
    margin-left: 10px;
    margin-right:10px;
    overflow: hidden;
}
.pathway_search {
    margin-top: 6px;
}
.pathway_li {
    margin-bottom: 0 !important;
}
.pathway_li_clpProblem {
    width: calc(100% - 599px);
}
.pathway_li_clpProblem_tab {
    flex: 1 1 10px;
    width: 10px;
    max-width: 300px;
}
.pathway_li_clpProblem_tab .select_prbl_section > div:nth-of-type(1) > div:nth-of-type(1) {
    margin-right: 24px !important;
}
.pathway_li_clpProblem_tab .ant-input-search {
    width: 100%;
}
.pathway_li_clpProblem_tab.min_width_problem_react_select {
    min-width: 190px;
}
.pathway_li_clpProblem_tab .ant-input-search input {
    width: 100%;    
}
.pathway_li_clpProblem_tab .ant-input-suffix i {
    cursor: pointer;
}
.sythesis_modal_table  {
    max-height: 300px;
    overflow: auto;
    border-bottom:1px solid rgba(0, 0, 0, 0.1);
}
.sythesis_modal_table table {
    border-collapse: separate;
    border-spacing: 0px;
    border-top:0px;
    border-bottom:0px;
}
.sythesis_modal_table table tr {
    border-bottom: none;
}
.sythesis_modal_table table tr td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05) ;
}
.sythesis_modal_table table tr th {
    position:sticky;
    top: 0;
    border-top:1px solid rgba(0, 0, 0, 0.1);
}
.radio_grp_container span{
    font-size: 12px;
}
.pblm_btn_container {
    justify-content:flex-end;    
}
.pblm_btn_container .tool_tip .anticon-info-circle {
    font-size: 25px;
    margin-right: 5px;
    margin-top: 3px;
}
.pathway_form_btn .item_invalid {
    top:-15px !important;
    left:15px;
    font-size: 10px;
}
.pathway_form_btn .invalid_right {
    left:5px;
}
.pathway_form_btn .invalid_left_container {
    top: 123px !important;
    left: 0;
    z-index: 99;
}
.txtarea_btn_container .text_area_treatment {
    height: 75px !important;
}
.task_btn_container .ant-btn {
    padding-right:8px;
}
.task_btn_container .ant-btn .anticon-caret-down{
    margin-right:0px;
    padding-left: 5px;
}
.problem_content_container {
    height:calc(100% - 55px);
    overflow-y: scroll;
}
.problem_content_container.problem_content_container_reorder {
    height:calc(100% - 90px);
}
.problem_content_container .resizable_table {
    overflow: auto;
}
.full_screen_with_filter .problem_content_container {
    height:calc(100% - 38px) !important;
}
@media (max-width:767px) {
    .copy_section .mr-20 {
        margin-right: 0px;
        margin-top: 10px !important;
    }
    .copy_section {
        display: grid !important;
    }
    .copy_section .mb-10{
        margin:auto;
    }
    .copy_button {
        min-width: 195px !important;
        text-align: left;
    }
    .intervention_details .id_total_section {
        padding-right: 0px;
    }
    
    .intervention_details .id_total_section span {
        display: inline-block;
        min-width: auto;
        margin-left: 0px;
    }
    
    .intervention_details .id_total_section * {
        font-size: 16px;
    }
    .margin_top_24px_special {
        margin-top: 24px;
    }

}
.copy_button {
    border: none !important;
    box-shadow: none !important;
}
.word_break {
    word-break: break-all;
    word-break: break-word;
    white-space: normal;
    width: 100%;
}
@media (max-width:767px) {
    .intervention_header .mb-10{
        margin-bottom: 10px;
    }
}
@media (max-width:991px) {
    .intervention_header {
        margin-top: 10px;
    }
}
.image_scroll_box {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    height: auto;
    display: inline-block;
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
    width: 100%;
}

.image_scroll_box .image-box img {
    cursor: pointer;
}

.image_scroll_box .image-box {
    width: 300px;
    display: inline-block;
    position: relative;
    padding: 20px;
    margin-right: 15px;
}

.validated {
    color: white !important;
    background-color: #3fa918;
    border: 1px solid #3fa918 !important;
}

.validated:hover {
    color: white !important;
    background-color: #369614;
    border: 1px solid #369614 !important;
}

.not_validated {
    color: white !important;
    background-color: #a23923;
    border: 1px solid #a23923 !important;
}

.not_validated:hover {
    color: white !important;
    background-color: #913422;
    border: 1px solid #913422 !important;
}

.recommend_btn_green > button {
    background-color: #369614 !important;
    border: 1px solid #369614 !important;
}
body .intervention_header .recommend_btn_green .scanned_intervention_sub_btn:hover {
    background-color: #369614 !important;
    border: 1px solid #0e38e4 !important;
    border-right: none !important;
}
body .intervention_header .recommend_btn_red .scanned_intervention_sub_btn:hover {
    background-color: #a23923 !important;
    border: 1px solid #0e38e4 !important;
    border-right: none !important;
}
.recommend_btn_red > button {
    background-color: #a23923 !important;
    border: 1px solid #a23923 !important;
}

.recommend_btn_green > div, .recommend_btn_red > div {
    box-shadow: 5px 5px 10px #ddd !important;
}

.recommend_btn_green svg path, .recommend_btn_red svg path {
    fill: #ffffff !important;
    stroke: #ffffff !important;
}

.color-3740f2{
    color: #3740f2 !important;
}

.btn-group .left-button{
    position: relative;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-width: 135px;
}

.btn-group .right-button {
    position: relative;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-group .left_button {
    position: relative;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.btn-group .center_btn {
    border-radius: 0 0 0 0;
}

.btn-group .active-btton, .btn-group .left_button:hover, .btn-group .right-button:hover , .btn-group .center_btn:hover, .btn-group .single-button:hover {
    color: #fff !important;
    background: #3740f2;
    position: relative;
}

.btn-group .single-button[disabled], .btn-group .single-button[disabled]:hover .anticon-caret-down svg{ 
    color: rgba(0,0,0,.25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
    text-shadow: none;
    box-shadow: none;}
    
.btn-group .left-button input[type=checkbox], .btn-group .right-button input[type=checkbox]{
    position: absolute;
    top: 4px;
    left: 5px;
}
.mr-10{
    margin-right: 10px;
}

.mr-7{
    margin-right: 7px;
}
.uppercase{
    text-transform: uppercase;
}

.bg-intervention-details{
    background-color: #d4d4d4d5;
}
.ex_width_160 {
    width: 160px;
}
.justify_content_between {
    justify-content: space-between;
}
.common_input input {
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 0px 10px;
    height: 32px;
    outline: none;
    box-shadow: none;
    color: #000000;
}
.width_85 {
    width: 85px;
}
.pl-25{
    padding-left: 25px;
}
/* .copy_scroll{
    height: 250px;
    overflow: auto;
} */
.copy_scroll .ReactTable .rt-tbody{
    max-height: 200px;
    overflow: auto;
} 
.min_width_90 {
    min-width: 90px !important;
}
.min_width_75 {
    min-width: 75px !important;
}
.pathway_form_btn  .validation_argumentation {
    top : 96px !important;
}
.pathway_form_btn  .validation_risk {
    top : 54px !important;
}
.clinical_pathway_container {
    height: calc(100% - 40px) !important;
}
.clinical_pathway_container.clinical_pathway_container_h_100 {
    height: 100% !important;
}
.hospital_text_clinical_pathway {
    font-size: 15px;
    color:  #3740f2 !important;
}
.copy_btn_modal {
    display: flex;
    justify-content: flex-end;
}
/* .pathway_tab {
    position: sticky;
    top: 80px;
    z-index: 99;
    background-color: #ffffff;
}
.pathway_sign_complication {
    position: sticky;
    top: 124px;
    z-index: 99;
    padding-top: 15px;
    background-color: #ffffff;
} */
.pathway_sign_complication {
    padding-top: 15px;
}
.pathway_problem_table_sign {
    height: calc(100vh - 280px);
    overflow: scroll !important;
    overflow-x: hidden;
}
.pathway_problem_table_sign .ReactTable .rt-table .rt-tbody{
    max-height: calc(100vh - 360px);    
}
.pathway_scrollable_content {
    height: calc(100vh - 255px);
    overflow: scroll;
    overflow-x: hidden;
    position: relative;
}
.health_pathway_scrollable_content {
    height: calc(100vh - 2px);
    overflow: scroll;
    overflow-x: hidden;
    position: relative;
}
.task_label {
    color: #3740f2 !important;
}
.pathway_code {
    width: 205px;
}
.pathway_hospital {
    width: 175px;
    padding-left: 10px;    
}
.pathway_code_btn {
    display: flex;
    width: calc(100% - 380px);
    justify-content: flex-end;
    padding-right: 15px;    
}
.collaps_data .ant-collapse-header{
    background-color: #0477bd;
    color: #ffffff !important;
}
.collapse_sub .ant-collapse-header{
    background-color: #fafafa;
    color: #000000 !important;
}
.collapse_sub .subStep_heading {
    color: #0477bd !important;
}
.collapse_sub .ant-table-empty .ant-table-body {
    overflow-x: hidden !important;
}
.select_problem_edit .css-kj6f9i-menu {
    z-index: 9; 
}
/* .sub_step_container .ant-table-body {
    min-height: 200px;
    max-height: calc(100vh - 300px);
    overflow-y: scroll;
} */
.sub_step_container .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 9;
}
.sub_step_container .ant-table-tbody tr .icon_td_width {
    
    max-width: 110px !important;    
    min-width: 110px !important;
    width: 110px !important;
}
.fullscreen_sub_step_container .ant-table-tbody tr .icon_td_width {
    
    max-width: 110px !important;    
    min-width: 110px !important;
    width: 110px !important;
}
.icon_div_width {
    max-width: 110px !important;
}
.fullscreen_sub_step_container .ant-table-tbody tr .icon_td_width_60 {
    max-width: 60px !important;    
    min-width: 60px !important;
    width: 60px !important;
}
.sub_step_container .ant-table-tbody tr .icon_td_width_60 {
    max-width: 60px !important;    
    min-width: 60px !important;
    width: 60px !important;
}
.icon_td_width_60 .icon_div_width {
    max-width: 60px !important;
}
.fullscreen_sub_step_container .ant-table-tbody tr .icon_td_width_40 {
    max-width: 40px !important;    
    min-width: 40px !important;
    width: 40px !important;
}
.sub_step_container .ant-table-tbody tr .icon_td_width_40 {
    max-width: 40px !important;    
    min-width: 40px !important;
    width: 40px !important;
}
.icon_td_width_40 .icon_div_width {
    max-width: 40px !important;
}
/* .sub_step_container .ant-table-tbody tr .icon_td_width div{
    min-width: 90px !important;
    max-width: 110px !important;
} */
/* .drobdown_select_data {
    background-color: red !important;
} */
.switch_container_pathway .ant-switch {
    min-width: 30px !important;
}
.empty_step_hidden {
    font-weight: 500;
    color: #FF0000;
    height: 15px;
    position: absolute;
    top: -5px;
}
.empty_step_hidden_clinical_pathway {
    font-weight: 500;
    color: #FF0000;
    height: 15px;
}
.clinical_pathway_hospital {
    font-weight: 500;
    color: #FF0000;
    height: 15px;
    font-size: 16px;
    margin-left: 8px;
}
.create_problem {
    width: 940px !important;
}
.create_problem .ant-modal-content .ant-modal-body {
    overflow-y: auto;
}
.problem_seperate {
    box-shadow: 2px 2px 15px 3px #e4e4e4;
    width: 100%;
    margin-top:20px;
    margin-bottom:20px;
}
.create_problem label{
    word-break: break-all;
}
.wide_reorder {
    width: calc(100vw - 100px) !important;
}
.wide_reorder .ant-modal-content .ant-modal-body {
    overflow-y: auto;
}
.resizable_table {
    height: calc(100%);
    overflow-y: scroll;
    border-top: 1px solid #cccccc;
    /* border-bottom: 1px solid #cccccc; */
}
.full_screen .resizable_table {
    height: calc(100vh - 45px) !important;    
}
.resizable_table tbody:nth-child(even) {
    background-color: #e6fafa;
}
.resizable_table tbody {
    border-bottom: 2px solid #cccccc !important;
}
.resizable_table .thead th , .resizable_table .thead td {
    position: sticky;
    top: 0px;
    text-align: center !important;

}
.resizable_table th {
    font-family: 'Proxima Nova', sans-serif !important;
    font-weight: normal;
    text-align: left !important;
    font-size: 12px;
}
.resizable_table .columnResizer {
    background-color: #cccccc;
    max-width: 1px !important;
    min-width: 1px !important;
    width: 1px !important;
    padding: 0px !important;
} ​
.resizable_table tbody tr .resizable_td {
    padding: 0px !important;
    max-width: 1px !important;
    min-width: 1px !important;
    width: 1px !important;
    background-color: #cccccc;
}
.resizable_table  table {
    border-collapse: separate;
    border-spacing: 0px;
}
.resizable_table td {
    /* padding: 1px !important; */
}
.table_div .resizable_td {
    padding: 1px !important;
    background-color: #cccccc;
    
}
.table_div .td_padding_left {
    padding-left: 28px !important;
}
.table_div .td_padding_left p{
    margin-bottom: 0px;
}
/* .resizable_table table thead tr th{
    word-break: break-all;
    word-break: break-word;
}
.resizable_table table tbody tr td{
    word-break: break-all;
    word-break: break-word;
} */

.no_scroll_pathway .public-DraftEditor-content {
    min-height: 50px !important;
    overflow: auto !important;
    height: auto !important;
}
.select_intervention .css-dgoi55-control {
    max-height: 40px;
}
.create_additional .ant-modal-body {
    padding-top: 5px;
}
.tool_tip .ant-tooltip-inner{
    min-width: 100px;
    min-height: 50px;
    max-height: 200px;
    overflow-y: scroll;
    background-color: #ffffff;
}

.min_width_150 {
    min-width: 150px;
}
.min_width_200 {
    min-width: 200px;
}
.min_width_250 {
    min-width: 250px;
}
.invalid_top__13 {
    margin-top: -13px !important;
}
.invalid_top_7 {
    margin-top: 7px !important;
}
.invalid_top_11 {
    margin-top: 11px !important;
}
.show_hide_col_li {
    
}
.show_hide_col_li .show_hide_col_li_check {
    width: 25px;
    display: inline-block;
    vertical-align: top;
}
.show_hide_col_li .show_hide_col_li_text {
    width: calc(100% - 25px);
    display: inline-block;
    vertical-align: top;
}
.min_width_problem_react_select > div:nth-of-type(1) > div:nth-of-type(1) {
    min-width: 155px;
    margin-top: 4px;
    min-height: 32px;
    height: 35px;
    box-shadow: none !important;
}
.min_width_problem_react_select > div:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(1) {
    padding: 1px 8px;
}
.min_width_problem_react_select > div:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(1) {
    max-height: 34px;
}
.min_width_problem_react_select > div:nth-of-type(1) > div:nth-of-type(2) {
    width: auto;
    min-width: 100%;
}
.min_width_problem_react_select > div:nth-of-type(1) > div:nth-of-type(2) > div:nth-of-type(1) > div {
    white-space: nowrap;
}
.reorder_modal_select {
    /* position: relative; */
    z-index: 99;
}
.reorder_modal_select > div:nth-of-type(1) > div:nth-of-type(1) {
    min-width: 155px;
    min-height: 36px;
    height: 32px;
    box-shadow: none !important;
}
.reorder_modal_select > div:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(1) {
    padding: 1px 8px;
}
.reorder_modal_select > div:nth-of-type(1) > div:nth-of-type(2) {
    width: auto;
    min-width: 100%;
}
.reorder_modal_select > div:nth-of-type(1) > div:nth-of-type(2) > div:nth-of-type(1) > div {
    white-space: nowrap;
}
.pathway_li_clpProblem_tab.select_prbl_section_container {
    min-width: 165px;
}
.tab_search .pathway_li_clpProblem_tab input {
    width: 100%;
}
.invalid_pathway_create {
    position: absolute;
    right: 0;
    top: -17px;
    font-size: 12px;
    color: #f79595;
}
.clinical_pathway_breadcrumb_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
}
.clinical_pathway_breadcrumb_container .clinical_pathway_breadcrumb {
    flex: 1 1 10px;
}
.clinical_pathway_breadcrumb_container .clinical_pathway_breadcrumb .pole_breadcrumb {
    border-bottom: none;
    padding: 15px 10px 10px 10px;
}
.clinical_pathway_tabs.nav-tabs > li > a {
    color: black;
}
.clinical_pathway_tabs.nav-tabs > li.active > a, .clinical_pathway_tabs.nav-tabs > li.active > a:focus ,.clinical_pathway_tabs.nav-tabs > li.active > a:hover {
    border: 1px solid transparent;
    border-color: transparent !important;
}
.clinical_pathway_tabs.nav-tabs > li > a::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    background-color: red;
    width: 0;
}
.clinical_pathway_tabs.nav-tabs > li.active > a::before, .clinical_pathway_tabs.nav-tabs > li.active > a:focus::before,.clinical_pathway_tabs.nav-tabs > li.active > a:hover::before{
   width: 100%;
}
.hospital_red_shadow_select > div:nth-of-type(1) > div:nth-of-type(1) {
    box-shadow: 0px 0px 0px 2px red;
    border-color: red !important;
}
.pathway_scrollable_content.full_screen {
    position: fixed !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background: white;
    overflow-y: auto;
    height: 100% !important;
    padding: 45px 15px 15px 15px;
}
.z_index_101_select {
    z-index: 101;
}

.interventions_icon_doctor{
    margin-left: 5px;
    height: 22px;
}
.interventions_icon_nurses{
    margin-left: 5px;
    height: 22px;
}

.interventions_icon_doctor_edit{
    height: auto;
    /* width: 9px; */
}
.interventions_icon_nurses_edit{
    height: auto;
    /* width: 14px; */
}
.clinical_pathway_table_container {
    height: calc(100% - 47px);
}
.problem_pathway_section {

	height: calc(100% - 30px)
}

/* height: calc(100% - 100px); */

.invalid_clinical_pathway {
    position: absolute;
    right: 0px;
    top: -14px;
    font-size: 12px;
}
.invalid_clinical_pathway_bottom {
    position: absolute;
    right: 0px;
    font-size: 12px;
}
.quick_reorder_table_modal .ant-modal-body {
    position: relative;
    margin: 10px 0px;
    padding: 0px 24px;
}
.quick_reorder_table_modal .ant-modal-body .ant-table-body table {
   border-top:none;
}
.quick_reorder_table_modal .ant-modal-body .ant-table-body table thead {
    position: sticky;
    top: 0;
    border-collapse: separate;
    z-index: 9;
}
.quick_reorder_table_modal .ant-modal-body .ant-table-body table thead th {
    border-top: 1px solid #e8e8e8;
}
.quick_reorder_table_modal .ant-modal-body .ant-table-body table tbody{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.warning_msg {
    position: absolute;
    right: 15px;
    top: -16px;
    font-size: 11px;
    color: chocolate;
}
.synthesis_modal_table .ReactTable {
    max-height: calc(100vh - 350px);
}
.font-12 {
    font-size: 12px !important;
}
.text_underline {
    text-decoration: underline !important;
}
.drag_modal {
    overflow-y: hidden
}
.drag_modal .ant-modal-body {
    min-height: 400px;
    max-height: calc(100vh - 155px);
    overflow-y:auto;
}
.modal_mini_height_auto .ant-modal-body {
    min-height: auto !important;
}
.section_type_td {
    background-color: #acc6cf !important;
    text-align: center;
    position: sticky;
    padding: 2px 10px !important;
    font-size: 12px !important;
    font-weight: bold
    
}
.section_type_td_standard_chart {
    background-color: #acc6cf !important;
    text-align: center;
    /* position: sticky;
    top: 46px; */
    z-index: 3 !important;
    padding: 2px 10px !important;
    font-size: 12px !important;
    font-weight: bold;
    
}
.section_type_td_standard_chart > div:nth-of-type(1) {
    width: 100%; 
    max-width: calc(100vw - (20vw + 101px));
    text-align: left !important;
    position: sticky;
    left: 10px;
}
.height_common_btn {
    height: 32.5px
}
.common_cancel_btn_container .ant-btn {
    font-size: 11px;
}
.check_list {
    color: #2016b8 !important;
}
.check_list_bold {
    font-weight: bold;
}
.drop-round-arrow .anticon-right-circle svg{
    width: 20px;
    height: 20px;
}
.drop-round-arrow .anticon-down-circle svg{
    width: 20px;
    height: 20px;
}
.overflow_visible_modal .ant-modal-body {
    overflow: visible;
}

.svg_action_icon svg{
    width: 20px;
    height: 20px;
    margin: 0px 5px;
}
.min_height_auto { min-height: auto !important;}
.min_height_250 {
    min-height: 250px;
    overflow-y:auto;
}
.height_15{
    height: 15px;
}
.height_30{
    height: 30px !important;
}
.height_20{
    height: 20px !important;
}
.margin_top_0{
    margin-top: 0px !important;
}
.info_text{
    background-color: #1790ff;
    color: white !important;
    padding: 0px 2px;
    float: right;
}
.share_pathway{
    position: relative;
}
.share_pathway .share_to_text{
    margin-left: 20px;
}
.share_pathway svg{
    position: absolute !important;
    width: 20px !important;
    height: 20px !important;
    top: 0px !important;
    margin-left: -5px !important;
}
.btn-group:hover svg{
    color: white !important;
}
.btn-group:hover span{
    color: white !important;
}

.scroll_window{
    max-height: calc(100vh - 300px) !important;
    overflow-y: scroll !important;
}
.simple_table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;
    overflow: auto;
  }
  
  .simple_table td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  .simple_table td{
    padding: 4px 8px;
  }
  .simple_table th{
    -webkit-box-shadow: inset -2px -1px 26px 43px rgba(243,244,248,1);
    -moz-box-shadow: inset -2px -1px 26px 43px rgba(243,244,248,1);
    box-shadow: inset -2px -1px 26px 43px rgba(243,244,248,1);
}
  .simple_table tr:nth-child(even) {
    background-color: #f7f7f791;
  }
  
  .section_type_dropdown .ant-select, .ant-select-selection{
      height: 32px !important;
  }
  .section_type_dropdown .ant-select-selection-selected-value{
    font-size: 12px !important;
    line-height: 35px;
    text-indent: 5px;
  }
  .section_type_dropdown .ant-select .ant-select-dropdown-menu-item{
    font-size: 12px !important;
    color: #a0a0a0 !important;
  }

.cost_tabs .cost_section_a_Tag{
    margin-bottom: 15px;
    display: inline-block;
}
.cost_table_section .ReactTable .rt-tbody {
    max-height: 325px;
    overflow-y: scroll;
}
.word_break {
    word-break:break-all;
    word-break:break-word;
    white-space:pre-wrap;
}

@media (min-width:992px) {
    .cost_modal {
        width: 900px !important;
    } 
}
@media (max-width:991px) {
    .cost_modal {
        width: auto !important;
    } 
}
.bold_dec {
    font-family: 'Proxima Nova Bold', sans-serif;
    color: black;
}
.font-size-10 {
    font-size: 10px !important;
}
.overflow-x-hidden-model .ant-modal-body{
    overflow-x: hidden !important;
}
.check-box-block {
    width: auto;
    min-width: 20px;
    display: inline-flex;}

.react-select-options .css-kj6f9i-menu{
    width: auto !important;
    max-width: 400px;
}
.react-select-options .css-kj6f9i-menu div {

    width: 100% !important;
}

/* .model_attached_tr {
    color: #0477bd !important;

}
.model_attached_td {
    color: #0477bd !important;

} */

.model_attached_td .anticon-delete{
    float: none !important;
    margin: auto !important;
}
.information{
    
    padding: 5px 10px;
    float: left;
    border-radius: 5px;
    background-color: #f0ba61;
    color: #804c0a;
    font-size: 13px;
    position: relative;
    -webkit-box-shadow: 0px 0px 4px 1px rgba(201,201,201,1);
    -moz-box-shadow: 0px 0px 4px 1px rgba(201,201,201,1);
    box-shadow: 0px 0px 4px 1px rgba(201,201,201,1);
    text-align: left;
}
.information ::before{
    content: "*";
    display: inline-block;
    padding-right: 5px;
    vertical-align: middle;
    font-weight:900;
}
.disabled{
    pointer-events: none;
}
.pending-status-true img{
    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(75%) contrast(119%);
}

.pending-status-false img{
    filter: invert(48%) sepia(1%) saturate(2476%) hue-rotate(86deg) brightness(75%) contrast(119%);
}
.model_attached_count{
    float: right;
    /* margin-bottom: -25px; */
}
.square-bullat::before{
    content: "▪ ";
    color: #000000;
}

.right-arrow-bullat::before{
    content: "▸ ";
    color: #000000;
}
.checkList-edit-apply-to-all-option{
    text-align: right;
    
}
.checkList-edit-apply-to-all-option label{
    margin-left: 5px;
}
/* Z-index */
.z-index-1{z-index: 1 !important;}
.z-index-2{z-index: 2 !important;}
.z-index-3{z-index: 3 !important;}
.z-index-4{z-index: 4 !important;}
.z-index-5{z-index: 5 !important;}
.z-index-6{z-index: 6 !important;}
/* mini-height */
.min-height-250{min-height: 250px !important;}
.min-height-260{min-height: 260px !important;}

.active_blue {
    color: #3740f2 !important;
}
.notice_message{
    width: 50%;
    height: 100px;
    text-align: center;
    vertical-align: middle;
    float: left;
    padding: 40px 20px;
    margin-top: 33px;
    color: #b32323;
}

.succss_model{
    width: 300px !important;
}
.succss_model .ant-modal-header{display: none;}
.succss_model .ant-modal-close{display: none;}
.succss_model .ant-modal-footer{display: none;}
.succss_model .anticon{
    text-align: center;
    width: 100%;
}
.succss_model .anticon svg{
    width: 38px;
    height: 38px;
    margin: auto;
    fill: #369614;
    color: #369614;
}

.succss_model .message{
    width: 100%;
    text-align: center;
    padding-top: 10px;
    color: #050020;
}
.copy_clp_modal{top: 20px;}
.copy_clp_modal .ant-modal-body{
    /* height: calc(100vh - 181px) !important; */
    height: calc(100vh - 104px) !important;
    max-height: calc(100vh - 98px);
}
.copy_clp_modal .ant-modal-body{padding-top: 5px;}
.copy_clp_modal .ant-modal-header{padding-bottom: 0px;}
.copy_clp_modal .ant-steps-item-content{margin-top: 0px;}
.copy_clp_modal .copy-content{
    /* min-height:calc(100vh - 303px); */min-height: calc(100vh - 221px);height: auto !important;}
.copy_clp_modal .custom_steps_label .ant-steps-item-title { width: 350px !important;}
.react-select-option-label {position: relative;}
.react-select-option-label label{position: absolute; right: 2%; top: 5px;}
.quick-order-empty-list{ text-align: center;margin-top: 11%;}
.shared_community { border: 1px solid #447e15 !important; color: #447e15 !important;}
.shared_community svg {color: #447e15 !important;}
.shared_community:hover{ background-color: #447e15 !important;; color: white !important;}
.shared_community:hover svg, .shared_community:hover .share_to_text{ color: white !important;}

.copy_clp_duration_info {
    width: 22%;
  float: right;
  font-size: 11px;
}
.clinical-pathway-reorder-icon-container {
	padding: 0 2px;
	display: flex;
	background: #6a7e8c;
}
.clinical-pathway-reorder-icon-container .icon-holder {
    margin: 4px 2px;
    cursor: pointer;
    border: 1px solid #585b75;
    width: 25px;
    height: 25px;
    border-radius: 3px;
}
.clinical-pathway-reorder-icon-container .custom-svg {
	display: inline-block;
    margin-left: 5px;
	margin-top: 2px;
}

.problem_pathway_1 {

	height: calc(100% - 115px);
}
.full_screen .problem_pathway_1 {

	height: calc(100% - 45px);
}
.full_screen .problem_pathway_1 .problem_content_container { height: 100% !important;}
.full_screen .problem_pathway_1 .resizable_table {
    height: calc(100vh - 84px) !important;
  }