.no_cell {
    border-top: none !important;
    border-left: none !important;
    background: white !important;
}
.standard_care_chart_table_div {
    height: calc(100% - 38px);
}
.standard_care_chart_table_div.standard_care_chart_table_div_reorder {
    height: auto;
    height: calc(100% - 85px);
}
.standard_care_chart_table_div .table_div {
    overflow: auto;
    height: 100%;
    
}
.customized-scrollbar {
    display: block !important;
    overflow: auto;
    scrollbar-color: #6e6e6e !important;
    scrollbar-width: strong;
    -webkit-appearance: scrollbartrack-vertical;
    -webkit-appearance: scrollbarthumb-vertical;
  }
/* Let's get this party started */
.scrollbar::-webkit-scrollbar {
    display: block !important;
    width: 5px !important; 
    height: 5px !important; 
}
 
.scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px !important; 
}
 
.scrollbar::-webkit-scrollbar-thumb {
  background: #6e6e6e;
  border-radius: 20px;
}
/* .table_div {
    overflow: scroll;
    height: calc(100vh - 330px);
} */

table.standard_care_chart_table {
    border-collapse: separate;
    border-spacing: 0px;
}

table.standard_care_chart_table th,
table.standard_care_chart_table td {
    border: 1px solid #dedede;
    word-break: break-all;
    word-break: break-word;
}
table.standard_care_chart_table .border_highlight {
    border-right: 2px solid #dedede;
}

table.standard_care_chart_table thead {
    position: sticky;
    top: 0;
    z-index: 3;
}
table.standard_care_chart_table thead th {
    padding: 0px 3px;
    min-width: 50px;
}

table.standard_care_chart_table .no_cell {
    position: sticky;
    left: 0;
    z-index: 1;
}

table.standard_care_chart_table thead tr:nth-child(1) th:nth-child(n+1) {
    background: #e2f0f4;
}

table.standard_care_chart_table thead tr:nth-child(2) th:nth-child(n+1) {
    min-width: 50px;
    max-width: 50px;
    background: #d4f4e9;
    word-break: break-all;
    white-space: pre-wrap;
}

table.standard_care_chart_table tbody tr th:first-child {
    min-width: 180px;
    max-width: 180px;
    background: white;
    word-break: normal;
    font-family: 'Proxima Nova Normal', sans-serif;
    font-weight: 600;
}

table.standard_care_chart_table tbody tr td:nth-of-type(n + 2) {
    max-height: 40px;
}

table.standard_care_chart_table tbody tr td:first-of-type {
    min-width: 200px;
    max-width: 200px;
    text-align: center;
}
table.standard_care_chart_table tbody tr .td_hide {
    min-width: 200px;
    max-width: 200px;
    text-align: center;
}
table.standard_care_chart_table tbody tr td:first-of-type .anticon {
    padding-left: 10px;
    float: right;
}

.cell_tmp_green {
    background: #87caad;
}
.cell_tmp_green .floating_label{
    background: #87caad;
}
.cell_tmp_orange {
    background: #88DFBA;
}

.cell_tmp_orange .floating_label{
    background: #88DFBA;
}
.cell_tmp_grey {
    background: #f2f0f1;
    text-align: center;
}
.cell_tmp_light_blue {
    background: #e6fafa;
    text-align: center;
}
.empty_head_reorder {
    width: 38px; 
}
.clinical_pathway_table {
    height: calc(100% - 152px);
}
.clinical_pathway_table.full_screen {
	height:calc(100% - 38px) !important;
}

/* height: 100%; */

.full_screen .standard_care_chart_table_div { height: calc(100% - 35px) !important; }
.full_screen .standard_carechart_height{     height: calc(100vh - 80px) !important; }
.floating_label {
    position: relative;
    left: 0;
    top: 0;
    background: #f2f0f1;
    /* text-align: left !important; */
    padding: 7px 10px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cell_tmp_light_blue .floating_label{
    background: #e6fafa;
}

/* table scroll fixed columns */

table.standard_care_chart_table tbody td:nth-of-type(1) {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: white;
}

table.standard_care_chart_table tbody td:nth-of-type(2) {
    position: sticky;
    left: 200px;
    z-index: 1;
    background: white;
    width: 200px;
    max-width: 200px;
    min-width: 200px;
}

table.standard_care_chart_table.third_col_sticky_intervenants_duration tbody td:nth-of-type(3) {
    position: sticky;
    left: 580px;
    z-index: 1;
    background: white;
    width: 200px;
    max-width: 200px;
    min-width: 200px;
}

/* table.standard_care_chart_table.third_col_sticky tbody td:nth-of-type(3) {
    position: sticky;
    left: 400px;
    z-index: 1;
    background: white;
    width: 200px;
    max-width: 200px;
    min-width: 200px;
} */
 

/* --for intervention and code--- */
table.standard_care_chart_table.third_col_sticky_intervenants tbody td:nth-of-type(3) {
    position: sticky;
    left: 280px;
    z-index: 1;
    background: white;
}
table.standard_care_chart_table.third_col_sticky_intervenants tbody td:nth-of-type(4) {
    position: sticky;
    left: 660px;
    z-index: 1;
    background: white;
}
/* --for intervention and code--- */



/* --for duration and code--- */
table.standard_care_chart_table.third_col_sticky_code_duration tbody td:nth-of-type(3) {
    position: sticky;
    left: 280px;
    z-index: 1;
    background: white;
}
table.standard_care_chart_table.third_col_sticky_code_duration tbody td:nth-of-type(4) {
    position: sticky;
    left: 660px;
    z-index: 1;
    background: white;
}
/* --for duration and code--- */

/* --for code only--- */
table.standard_care_chart_table.third_col_sticky tbody td:nth-of-type(3) {
    position: sticky;
    left: 280px;
    z-index: 1;
    background: white;
}
table.standard_care_chart_table.third_col_sticky tbody td:nth-of-type(4) {
    padding: 0 !important;
    /* position: sticky; */
    left: 660px;
    /* z-index: 1; */
    /* background: white; */
}
/* --for code only--- */

/* single-duration-only */
table.standard_care_chart_table.third_col_sticky_duration_only tbody td:nth-of-type(3) {
    position: sticky;
    left: 580px;
    z-index: 1;
    background: white;
}
/* single-duration-only */

/* intervention-duration-only */
table.standard_care_chart_table.third_col_stickypp tbody td:nth-of-type(3) {
    position: sticky;
    left: 580px;
    z-index: 1;
    background: white;
}
table.standard_care_chart_table.third_col_stickypp tbody td:nth-of-type(4) {
    position: sticky;
    left: 780px;
    z-index: 1;
    background: white;
}
/* intervention-duration-only */


table.standard_care_chart_table.forth_col_sticky tbody td:nth-of-type(4) {
    position: sticky;
    left: 660px;
    z-index: 1;
    background: white;
}
table.standard_care_chart_table.forth_col_sticky tbody td:nth-of-type(3) {
    position: sticky;
    left: 280px;
    z-index: 1;
    background: white;
}
table.standard_care_chart_table.forth_col_sticky tbody td:nth-of-type(5) {
    position: sticky;
    left: 860px;
    z-index: 1;
    background: white;
}



table.standard_care_chart_table_code.standard_care_chart_table.third_col_sticky tbody td:nth-of-type(3) {
    position: sticky;
    left: 280px;
    z-index: 1;
    background: white;
    width: 200px;
    max-width: 200px;
    min-width: 200px;
}

table.standard_care_chart_table_code.standard_care_chart_table.forth_col_sticky tbody td:nth-of-type(4) {
    position: sticky;
    left: 480px;
    z-index: 1;
    background: white;
    width: 200px;
    max-width: 200px;
    min-width: 200px;
}


table.standard_care_chart_table_code.standard_care_chart_table.forth_col_sticky tbody td:nth-of-type(5) {
    position: sticky;
    left: 680px;
    z-index: 1;
    background: white;
    width: 200px;
    max-width: 90px;
    min-width: 70px;
}

.td_max_width_380 {
    min-width: 380px !important;
    width: 380px !important;
    max-width: 380px !important;
}

.max_width_80 {
    max-width: 80px !important;
    width: 80px !important;
    min-width: 80px !important;
    word-break: break-all;
}

.max_width_60 {
    max-width: 60px !important;
    width: 60px !important;
    min-width: 60px !important;
    word-break: break-all;
}

.standard_care_chart_table .floating_label {
    left: 320px;
    width: calc(100% - 310px);
}
.standard_care_chart_table.standard_care_chart_table_code.third_col_sticky .floating_label {
    left: 480px;
    width: calc(100% - 480px);
}
.standard_care_chart_table.third_col_sticky .floating_label {
    left: 177px;
    width: calc(100% - 600px);
}
.standard_care_chart_table.forth_col_sticky.third_col_sticky .floating_label {
    left: 400px;
    width: calc(100% -  200px) !important;
    display: flex;
    align-items: center;
    height: 100%;
}
.transform_scale_relative {
    transform: scale(1);
}
.empty_cell_width {
    min-width: 50px !important;
}
.table_div table.standard_care_chart_table tr .standard_care_chart_disable_col {
    background-color: #ffffff !important;
    border: none !important;
    border-color: white !important;
    position: relative;
}
.disabler_chart_col {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* z-index: 999; */
    background-color: #ffffff !important;
}
.permently{
    width: 100%;float: left;position: absolute;pointer-events: none;margin-top: -10px;
}
.editable-input {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    padding: 2px;
}
.editable-input:disabled,
.disabled-input {
  cursor: not-allowed;
  opacity: 0.5;
  background-color: #f5f5f5;
}

.standard_care_chart_table .floating_label {
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
}

.cell_tmp_grey {
    text-align: center;
    vertical-align: middle;
}
.standard_care_chart_table.forth_col_sticky.third_col_sticky .floating_label {
    left: 170px;
    width: calc(100% - 200px) !important;
    display: flex
;
    align-items: center;
    height: 100%;
}