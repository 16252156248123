.import_total_records {
    margin-top: -2.7%;
}

.consummable_treatment_name .rt-tbody {
    height: calc(100vh - 503px);
}


.margin-top-25-check {
    margin-top:26px;
}
.password-info {
    position: absolute;
    top: 3px;
    left: 58px;
    color: rgb(234, 177, 73);
}
.password-info-user {
    color: rgb(234, 177, 73);
    margin-left: 5px;
    margin-top: 5px;
    position: unset;
}

.import_tab_li li a {
    color: black;
}

.change-password-info {
    position: absolute;
    top: 32px;
    right: -18px;
    color: rgb(234, 177, 73);
}
.color_input {
    height: 39px;
    padding: 0;
    margin: 0;
    border: 0;
    position: absolute;
    top: 20px;
    right: 15px;
    border-radius: 4px;
}
.color_code_input {
    width: calc(100% - 58px);
    padding: 4px 10px;
    outline: none;
    border:1px solid #ddd;
    cursor: pointer;
}
.color_code_input:focus {
    box-shadow: none;
    outline: none;
}
.color_input:focus {
    box-shadow: none;
    outline: none;
}
.color_code_validation.invalid {
    right: 15px;
}
.color_code_popup * {
    padding: 0;
    margin: 0;
}
.photoshop-picker {
    width: 100% !important;
}
.reload_button {
    color: #fff;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    padding: 0;
    line-height: 34px;
    float:right;
}
.reload_button i{
    vertical-align: sub;
}
.success_error_popover .success_error_key {
    min-width: 105px;
}
.code_label {
    position: absolute;
    bottom: -30px;
    left: 0;
    color: #da7c1a !important;
}
.w_100.margin-bottom-18 {
    margin-bottom: 20px !important;
}
.w_100.margin-bottom-18 input.form-control {
    height:32px !important;
}
.error_message_popover .ant-popover-title{
    color: #fff;
    background: #bb0d0d;
    padding: 6px 16px !important;
}
.error_message_icon i {
    float:right;
    position: relative;
    top: 4px;
}
.disable_color {
    color: #c5c0c0;
    cursor:not-allowed;
    pointer-events: none;
}

.sticky_special {
    border-bottom: 0px !important;
}
.special_case_elements {
    top: 7px !important;
    left: 0 !important;
    right: unset !important;
}

.local_test .w_100.di {
    margin-top: 4px;
    margin-left: 3px;
    width: 200px;
}
.eye_icon_container {
    position: relative;
}
.eye_icon_container input {
    padding-right: 36px;
}
.eye_icon_container a {
    position: absolute;
    right: 11px;
    top: 6px;
}
.warning_span {
    color: #da7c1a;
}

.sector_dropdown .w_200p {
    padding: 0px !important;
}
.subheader span {
    font-size: 13px;
}
.subheader span:nth-child(1) {
    color: black;
}
.subheader span:nth-child(2) {
    font-weight: 600;
}

/* consumable popup */

.consumable_popup_table {
    max-height: 300px;
    overflow: scroll;
}

.consumable_popup_table table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}
  
.consumable_popup_table table td, .consumable_popup_table table th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}
  
.consumable_popup_table table tr:nth-child(even) {
    background-color: #f6f6f6;
}

.popup_table .nav-tabs>li.active>a, .popup_table .nav-tabs>li.active>a:focus, .popup_table .nav-tabs>li.active>a:hover {
    color: #5c5c5c;
}
.popup_table .nav-tabs>li>a {
    color: #5c5c5c;
}

.copy_color {
    color: #b5b5b5 !important;
}
.fr_imp {
    float:right !important;
}
.width-80-per {
    width:80% !important;
}
.table_step_open_height .rt-tbody {
    height: calc(100vh - 347px) !important;
}
.table_step_open_height {
    height: auto !important;
}
.step_open_btn {
    background-color: transparent;
    margin-left: 10px;
    padding: 7.5px 25px 4px 25px;
    position: relative;
    top: 2px;
    border-radius: 6px;
    border: none;
    color: white;
    box-shadow: 2px 3px 15px 0px #ccc;
}
.step_open_btn svg {
    font-size: 17px;
}
.position_top_neg_7 {
    position: relative;
    top: -7px;
}
.min-height-42 {
    min-height: 42px;
}
@media (max-width:767px) {
    .margin-top-25-check {
        margin-top:0px;
    }
    .local_test .w_100.di {
        margin-top: 0px;
        margin-bottom: 20px;
        width: 100%;
    }
}
@media (max-width:767px) {
    .reload_button {
        float:left !important;
        margin-top:0px;
    }
    .search_with_reload {
        margin-top: 15px;
    } 
    .cosumable_bottom_margin {
        margin-bottom: 15px;
    }
    .cosumable_search .searchbox {
        margin-top: 15px !important;
    }
}


.entity_validation {
    top: unset !important;
    left: 24px;
    bottom: 17px;
}

@media (min-width: 992px){
    .entity_mapping_modal {
        width: 900px !important;
    }
    .entity_mapping_modal .ReactTable .rt-tbody {
        max-height: 200px;
        overflow-y: scroll;
    }
}


.cosumable_search .searchbox {
    width: 100%;
    margin-top: 24px;
}


.search_seized_month .searchbox{
    width: 100%;
}

.icon_icon {
    width: 150px;
    margin-left: auto !important;
}

.icon_icon > div {
    width: 25px;
    display: inline-block;
    margin: 0 !important;
    float: right;
}
.import_div {
    width: 180px;
    display: inline-flex !important;
    padding: 3px 0px;
    float: right !important;
    margin-right: 20px;
}

.import_div label {
    width: 100px;
    text-align: center;
    line-height: 32px;
    margin-bottom: 0px !important;
}

.running_section {
    border: 1px solid #ddd;
    padding: 15px;
    padding-bottom: 0px !important;
    margin-bottom: 50px;
    max-height: calc(100vh - 246px);
    overflow: scroll;
}

.running_container {
    background-color: #f6f6f6;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 15px;
    box-shadow: 4px 4px 3px #cccccc;
}
.running_label {
    font-size: 13px;
}
.running_label span span {
    font-weight: 600;
    color: #3c3c3c;
}
.import_no_data {
    padding-top: 50px;
    padding-bottom: 65px;
    text-align: center;
}
.common_button.del {
    background-color: #ec2020;
}
.common_button.running {
    padding: 8px 12px;
}
.common_button.del i {
    font-size: 16px;
    vertical-align: text-bottom;
}
.common_button.del:hover {
    background-color: #cd1f1f;
}
.import_a {
    display: inline-block;
    padding: 8px 12px;
    border: 1px solid #2a9bf1;
    color: #2a9bf1;
    border-radius: 6px;
    margin-left: 10px;
    text-decoration: none;
    font-size: 11px;
}
a.import_a[disabled] {
    color: rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.25);
}
.import_a i {
    margin-right: 10px;
}
.error_message_icon.import_icon i {
    margin-left: 7px;
    top: 2px !important;
}
.error_message_popover .breakall {
    word-break: break-all;
}

.entity_table_height {
    height: calc(100vh - 300px);
}
@media (min-width: 768px) and (max-width:991px){
    .import_div {
        width: 180px;
        display: inline-flex !important;
    }
    
    .import_div label {
        width: 80px;
    }
}
.gtin_table_height .rt-tbody {
    max-height: calc(100vh - 298px)
}


.import_assign_table .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
}
.import_assign_table .ReactVirtualized__Grid {
    overflow: auto !important;
}
.import_assign_table .rt-tbody .rt-td {
    overflow: visible;
}


.importation_recent_entity_name {

max-width: calc(100% - 614px);
display: inline-block;
vertical-align: top;
padding: 0px 15px;
word-break: break-all;
word-break: break-word;
}
.importation_recent_entity_button {
max-width: 614px;
display: inline-block;
vertical-align: top;
float: right;
padding: 0px 15px;
word-break: break-all;
word-break: break-word;
}
.pole_create_box_drag_drop {
    padding: 10px 25px;
    border-radius: 4px;
    border: 1px solid #ececec;
}
.width_select_box {
    width: 30%;
    padding: 0px 15px;
    display: inline-block;
    vertical-align: top;
}
.width_select_box_check {
    width: 10%;
    padding: 0px 15px;
    display: inline-block;
    vertical-align: top;
    margin-top: 20px;
}
.width_select_box .cosumable_search .searchbox {
    margin-top: 21px;
}
@media screen and (max-width:1250px) {
    .width_select_box_check {
        width: 16%;
    }
    .width_select_box { 
        width: 28%;
    }
}
.margin-0-auto {
    margin:0 auto !important;
}
.min-height-60 {
    min-height: 60px;
}

.filter_select_height_30 > div:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(1) {
    min-height: 30px !important; 
}
.width_30_per {
    width: 30%;
    display: inline-block;
    vertical-align: top;
}
.width_70_per {
    width: 70%;
    display: inline-block;
    vertical-align: top;
}
.ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
body .input_height_38 .w_100 input.form-control {
    height: 38px !important;
}

.ReactTable .input_ant_select_style {
    height: 38px !important;
}

.input_ant_select_style {
    height: 32px !important;
    cursor: pointer !important;
    background-color: hsl(0,0%,98%);
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    outline: 0 !important;
    position: relative;
    width: 100% !important;
    padding: 5px 37px 5px 8px;
    font-size: 12px !important;

}
.input_box_antselect_icon_con {
    position: absolute;
    top: 9px;
    right: 1px;
    padding: 0px 5px;
    border-left: 1px solid hsl(0,0%,80%);;
    height: 14px;
}
.input_box_antselect_icon_con svg {
    color: hsl(0,0%,80%);
    position: relative;
    top:-1px;
}
.input_box_antselect_icon_con .isvg {
    position: relative;
    top: -3px;
}
.select_option_visible_tb .rt-tbody .rt-td {
    overflow: visible;
}
.select_height_34 > div:nth-of-type(1) > div:nth-of-type(1) {
    height: 34px;
    min-height: 34px;
}

.highlight_row_empty {
    border: 1px #c45e0b solid;
}
/* .Reusable_medical_devices_table .ReactTable .rt-tbody {
    max-height: calc(100vh - 315px);
} */
.Equipment_cost_table .ReactTable .rt-table .rt-tbody {
    max-height: calc(100vh - 350px);
}
.width_200_p {
    width: 200px;
} 
.margin-0p_4p {
margin: 0px 4px;
}
.child_div_padding_0_4 > div:nth-last-of-type(1) {
    padding: 0px 4px !important;
}

.height_34 {
    height: 34px;
}
.vertical_align_top_a {
    vertical-align: top;
}
body .disabled_export > button {
    color: rgba(0, 0, 0, 0.25) !important;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
    background-color: transparent !important;
}
body .importation_recent_entity_button .disabled_export > button:hover {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.25) !important;
}
body .importation_recent_entity_button .disabled_export > button:hover .caret_down {
    color: rgba(0, 0, 0, 0.25) !important;
}
.float_none_imp {
    float: none !important;
}
.disabled_export {
    box-shadow: none !important;
}
.export_button_recent_tabs > button {
    background-color: transparent !important;
}
body .importation_recent_entity_button .export_button_recent_tabs > button:hover {
    color: #560775 !important;
}

.pole_create_name_input {
    display: block;
    width: 100%;
    height: 32px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    outline:  none !important;
}
.input_width_fill .ant-calendar-picker {
    width: 100%;
}
.input_width_fill .w_200p {
    width: 100%;
    padding: 0px 0px;
}
.filter_input_design {
    width: 100%;
    height: 32px;
    border: 1px solid #cecece;
    border-radius: 4px;
    padding: 0px 10px;
    outline: none !important;
}
.ant_input_search_field > input {
    width: 100%;
    height: 32px;
    border: 1px solid #cecece;
    border-radius: 4px;
    padding: 0px 10px;
    outline: none !important;
    border-color: #cecece !important;
}
.daignosis_intervention_table .rt-tbody {
    max-height: calc(100vh - 305px);
}
.width_select_box_consumable {
    width: 30%;
    padding: 0px 15px;
    display: inline-block;
    vertical-align: top;

}
.custom_col_container {
    width: 33.33333333%;
    display: inline-block;
    padding: 0px 11px;
}
.filter_right_align {
    width: calc(100% - 33.33333333%);
    display: inline-block;
    vertical-align: top;
    margin-top: 5px;
}
.template_details_check_box_width_30 {
    width: 30%;
    padding-right: 15px;
    display: inline-block;
    vertical-align: top;
}
.template_details_check_box_width_40 {
    width: 40%;
    display: inline-block;
    vertical-align: top;
}
@media screen and (max-width: 1250px) {
    .width_select_box_consumable {
        width: 28%;
    }
}

.w_30 {
    width: 30%;
}
.w_100_imp {
    width: 100% !important;
}
.height_32 {
    height: 32px !important;
}
.overflow_auto {overflow: auto;}

.w_25 {
    width: 25%;
}
.ml-3p{
    margin-left: 3%;
}
.mt-10p{
    margin-top: 10%;
}

.home-container{
    width: 100%;
    height: calc(90vh);
    position: relative;
}

.home-footer{
    position: absolute;
    bottom: 10px;
    left: 40%;
}

.infotext{
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #565555;
}
.padding-left-right-0{
    padding-left: 0;
    padding-right: 0;
}

.margin-top-1p{
    margin-top: 1%;
}

.margin-top-o-15p{
    margin-top: -15px !important;
}

.padding-top-1p{
    padding-top: 1%;
}


.ml-10{
    margin-left: 10px;
}
.field-container{
    width: 100% !important;
    display: flex;
    align-items: center;
}
.pl0-rl-0{
    padding-left: 0;
    padding-right: 0;
}

.pr-0{
    padding-right: 0;
}

.filed-text{
    padding-top:7px;
    margin-right: 5px;
    white-space: nowrap;
}
.strong{
    font-weight: bold;
}
.text-align-right{
    text-align: right;
}
.text-align-left{
    text-align: left;
}
.pl-10{
    padding-left: 10px;
}

.pr-10{
    padding-right: 10px;
}
.mr-5{
    margin-right: 5px;
}

.ml-5{
    margin-left: 5px;
}
.filter_top_align {
    margin-top: 30px !important;
}
.privacy_section{
    width: 100%;
    float: left;
    border: 1px solid #d9d9d9;;
    border-radius: 5px;
    margin-top: 27px;
}
.color_red{
    color: red;
}
.color_gray{
    color: gray;
}
.list_style {
    list-style: none !important;
}
.information_header{
    color: #dd423a;
    font-size: 14px;
}
.information_header .anticon{
    margin-top: 5px;
}
.information_header .anticon svg{
height: 30px;
width: 30px;
}

.information_header  .text{
    position: absolute;
width: 80%;
padding-left: 5px;
font-size: 16px;
}
.model_information .clp_name{
    font-size: 14px !important;
    color: #4e36ec !important;
    padding-bottom: 5px;
}
.model_information .clp_details{
    color: black !important;
}
.model_information .clp_details::before{
    content: '⇒ ';
    font-size: 15px;
}

.dynamic_description {
    font-size: 14px;
    color: #bb0d0d;
    text-align: left;

}
.dynamic_description ul{
    padding-left: 20px;
}