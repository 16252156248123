.pole_breadcrumb {
    padding:15px;
    text-transform: uppercase;
}
.ant-breadcrumb-link {
    font-size: 65%;
}
.ant-breadcrumb a {
    -webkit-transition: color .3s;
    -o-transition: color .3s;
    transition: color .3s;
}
.ant-breadcrumb-separator {
    font-size: 65%;
}
.common_button {
    padding: 8px 15px;
    border-radius: 6px;
    font-size: 11px;
    min-width: 100px;
    border: none;
    background-color: #3740f2;
}

.with_border {
    padding: 5px 13px;
    border-radius: 4px;
    font-size: 11px;
    min-width: 100px;
}
.searchbox {
    width: 300px;
}
.pole_create_section {
    padding:15px 0px 15px 0px;
}

.pole_create_box {
    width: 50%;
    padding: 25px;
    border-radius: 4px;
}

.common_button.special_create_class {
    float: right;
    margin-right: 15px;
    margin-top: 1px;
}

@media (max-width:767px){
    .searchbox {
        width: 200px;
    }
    .pole_create_box {
        width: 100%;
    }
    .common_button.mt_15 {
        margin-top: 15px;
    }
    .common_button.special_create_class {
        float: right;
        margin-right: 15px;
        margin-top: 0px !important;
    }
}