.description_head {
    padding: 7px 0px;
    text-align: center;
    border-bottom: 0px;
    /* font-weight: 700; */
    font-size: 14px;
    letter-spacing: 1px;
    /* border-top-left-radius: 8px;
    border-top-right-radius: 8px; */
    font-family: 'Proxima Nova Bold', sans-serif;
    /* background: var(--defenition_head);
    color: var(--white); */
    background: #6a7e8c;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;
}
.desc_edit button {
    background-color: transparent !important;
    /* border-color: var(--white) !important;
    color: var(--white) !important; */
    border-color: #ffffff !important;
    color: #ffffff !important;
    border: none;
    padding: 5px 15px;
    letter-spacing: 1px;
    float: right;
    font-family: 'Proxima Nova';
    font-weight: bolder;
    font-size: 10px;
    text-align: center;
    border-color: white;
    text-decoration: underline;
}
.desc_edit button span {
    text-decoration: underline;
}
.desc_edit button::before, .desc_edit button::after {
    content: none;
    display: none;
}
.description_container .desc_content {
    /* border: 1px solid var(--border);     */
    border:1px solid #dddddd;
    
    border-top: none;
    padding: 10px;
    width: 100%;
}
.description_container .desc_content textarea {
    resize: none;
    height: 100px;
    overflow: auto;
    outline: none;
    box-shadow: none;
    border: none;
    width: 100%;
}
.diagram_image {
    /* height: calc(100vh - (44px + 172px + 172px + 1rem)); */
    min-height: 150px;
    text-align: center;
}
.diagram_image .polaroid {
    box-shadow: 0 0px 7px 0 var(--boxShadow);
    /* height: calc(100% - 40px);
    min-height: calc(150px - 40px); */
    display: inline-block;
}
.diagram_image .polaroid img {
    /* max-height: 100%; */
    max-width: 100%;
    object-fit: contain;
}
div.container {
    text-align: center;
    padding: 10px 20px;
}
.main_header {
    color: black;
    padding: 10px 0px;
    font-family: 'Bold';
    font-size: 18px;
}
.settings_container {
    display: flex;
    justify-content: space-evenly;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 9;
    width: 240px;
    border: 1px solid #cccccc;
}
.settings_outer {
    width: 242px;
    height: 49px;
}
.home_btns {
    border: 1px solid #0477bd;
    color: #0477bd;
    background-color: #ffffff;
    margin: 10px;
    padding: 18px;
    padding-top:6px;
    padding-bottom: 3px;
    border-radius: 5px;
    text-transform: uppercase;
}
.home_btns:hover {
    border: 1px solid #0477bd;
    color: #ffffff;
    background-color: #0477bd;
}
.home_btns:focus {
    border: 1px solid #0477bd;
}
.home_btns:active {
    border: 1px solid #0477bd;
}
.popup_modal_content label{
    color: #000000;
    margin-right: 10px;
    width: 120px;
}
.node_details_modal {
    min-width: 625px;
}
.popup_modal_content .popup_modal_content_input > input{
    border-radius: 4px;
    padding: 0px 10px;
    height: 32px;
    outline: none;
    color: #000000;
    background-color: #ffffff !important;
    border: 1px solid #cccccc !important;
}
.popup_modal_content_input.height_24_input > input {
    height: 24px;
    width: 57px;
}
.popup_modal_content textarea{
    border-radius: 4px;
    padding: 0px 10px;
    min-width: 171px;
    outline: none;
    color: #000000;
    background-color: #ffffff !important;
    border: 1px solid #cccccc !important;
}
.radius_input input {
    width: 30px;
} 
.radius_input label {
    width: 50px;
} 
.popup_node label {
    width: 80px;
}
.click_upload {
    cursor: pointer;
}
.click_upload:hover {
    color: #0477bd;
}
.upload_text_limit {
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
    table-layout:fixed;
}
.edit_task_font input {
    width: 49px;
    height: 26px;
    padding-right: 0px;
}
.font_type_edit_task {
    cursor: pointer;
    font-size: 12px;
    margin-top:4px;
    margin-left: 7px;
}
.link_to {
    margin-left: 20px;
    margin-top:4px;
}
.select_link .ant-select-selection__clear {
    top: 14px !important;
}
.edit_task_label {
    margin-top: 4px;
}
.ant-modal-title { display: block ruby;}
.ant-modal-close-x { line-height: 45px;}
.node_details_modal .ant-modal-title {
    font-weight: 700 !important;
}
.healthpathway_edit {
    min-height: 100px;
    border: 1px solid #e6e6e6;
}
.diagram_scroll {
    overflow: auto;
}
.no_diagram {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 500;
}
.invalid_rename_healthpathway {
    position: absolute;
    right: 1px !important;
    top: 60px !important;
    font-size: 12px;
}
.fullScreen_button{
    left: 100%;
    margin-left: -115px;
    z-index: 999;
}
.fullScreen_button_hospitalid{
    left: 100%;
    margin-left: -40px;
    z-index: 999;
}

.diagram-action-holder {
    padding: 0px 15px;
    display: flex;
}
.diagram-action-holder .icon-holder {
    margin: 5px 3px;
    cursor: pointer;
    border: 1px solid #585b75;
    width: 25px;
    height: 25px;
    border-radius: 3px;
} 
.diagram-action-holder  .icon-holder  .anticon {
    font-size: 18px;
    margin-top: 2px;
    margin-left: 1px;
}
.diagram-action-holder .custom-svg {
    display: inline-block;
    margin-left: 2px;
}
.diagram-action-holder .custom-svg svg {
    margin-top: 2px;
}
.diagram-action-holder button {
    border: none;
background: none;
color: white;
text-decoration: underline;
padding: 0px;
height: 28px;
}
.diagram-action-holder button:hover {
    background: none;
    color: white;
    text-decoration: underline !important;
}