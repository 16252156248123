.showHideBox {
    margin-top:5px;
    float: right !important;
}
.showHideBox .showlabel{
    margin-left: 10px;
}
.showHideBox .shohidebutton{
    padding: 0px 10px !important;
    font-size: 16px;
}
.showHideBox  .shohideContent {
   background-color: #323ada;
}
.shohideContent {
    padding:20px;
 }
 .shohideContent .show_hide_col_li {
    border: 1px solid #dadada;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-bottom: 5px;
 }
 .shohideContent .show_hide_col_li_check {
     margin-right: 7px;
 }
 .shohideContent .shohidebuttoncontainer {
     display: flex;
     justify-content: space-between;
     margin-top: 10px;
 }
 .shohideContent_max_height_scroll{
     max-height: 300px;
     overflow: scroll;
 }
 .show_hide_col_li .show_hide_col_li_label {
    width: calc(100% - 32);
    display: inline-block;
    vertical-align: top;
}